import { media } from '@variables'
import styled from 'styled-components'

export const Row = styled.div`
    width: 100%;
    display: flex;
`

export const Col = styled.div`
    display: flex;
    flex-direction: column;
`

export const Head = styled(Row)`
    padding-bottom: 40px;
    flex-direction: column;

    ${media.tablet} {
        padding-bottom: 60px;
    }

    ${media.desktop} {
        align-items: center;
        flex-direction: row;
        padding-bottom: 110px;
        justify-content: space-between;
    }
    ${media.xl} {
        align-items: flex-end;
    }
`

export const Title = styled.h2`
    padding-top: 16px;

    @media (max-width: 1169px) {
        font-size: 44px;
        line-height: 52px;
    }
`

export const HeadCol = styled(Col)`
    width: 100%;
    justify-content: flex-end;

    p {
        padding: 10px 0;
    }

    :first-of-type {
        padding-bottom: 10px;
    }

    :last-of-type,
    :first-of-type {
        width: 100%;
        max-width: 520px;
    }

    ${media.desktop} {
        :first-of-type,
        :last-of-type {
            max-width: 100%;
        }
        :first-of-type {
            width: 60%;
        }
        :last-of-type {
            width: 40%;
        }
    }
`

export const Img = styled.img`
    width: 100%;
    height: auto;
`

export const Bottom = styled(Row)`
    top: -40px;
    position: relative;
    flex-direction: column;

    ${media.desktop} {
        top: -100px;
        flex-direction: row;
        justify-content: space-between;
    }
`

export const BottomImg = styled.img`
    width: 100%;
    height: auto;
    max-width: 60%;

    ${media.desktop} {
        max-width: 466px;
    }
`

export const BottomCol = styled(Col)`
    p {
        max-width: 400px;
        padding: 20px 0 40px 0;
    }

    :first-of-type {
        align-items: center;
    }
    :last-of-type {
        padding-top: 30px;
    }

    ${media.desktop} {
        align-items: flex-start;
        justify-content: center;

        :first-of-type {
            width: 60%;
        }
        :last-of-type {
            width: 40%;
        }
    }
`
