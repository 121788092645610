import throttle from 'lodash/throttle'
import { useEffect, useState } from 'react'

import { getScrollTop } from '@utils'

const useDocumentScrollThrottled = (callback: TODO_ANY) => {
    const [, setScrollPosition] = useState(0)
    let previousScrollTop = 0

    const onDocumentScroll = () => {
        const currentScrollTop = getScrollTop()

        setScrollPosition((previousPosition) => {
            previousScrollTop = previousPosition
            return currentScrollTop
        })

        callback({ previousScrollTop, currentScrollTop })
    }

    const onDocumentScrollThrottled = throttle(onDocumentScroll, 250)

    useEffect(() => {
        window.addEventListener('scroll', onDocumentScrollThrottled)
        return () =>
            window.removeEventListener('scroll', onDocumentScrollThrottled)
    }, [])
}

export default useDocumentScrollThrottled
