import styled from 'styled-components'

import { media } from '@variables'

export const LogosWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`

export const RowWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
`

export const Wrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;

    ${media.tablet} {
        display: none;
    }
`

export const BorderVertical = styled.div`
    width: 1px;
    opacity: 0.2;
    height: 100px;
    background-color: #979797;
`

export const BorderHorizontal = styled.div`
    width: 90%;
    height: 1px;
    opacity: 0.2;
    background-color: #979797;
`
