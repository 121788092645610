import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'

import Video from 'client/components/Video'
import Line from 'client/components/SVG/Line'
import { Overflow } from 'client/components/shared/styled'
import Container from 'client/components/shared/Container'
import VideoMobile from 'client/components/Video/VideoMobile'
import { Header, TextWrap, LineWrap, VideoWrap, Title } from './styled'

const Hero = () => {
    const [t] = useTranslation()
    return (
        <>
            <Header>
                <Container maxWidth={1310}>
                    <TextWrap>
                        <Overflow>
                            <Fade
                                delay={300}
                                duration={1500}
                                triggerOnce
                                direction="up"
                            >
                                <Title>{t('hero-title1')} </Title>
                            </Fade>
                        </Overflow>
                        <Overflow style={{ padding: '0 0 14px 0' }}>
                            <Fade
                                triggerOnce
                                direction="up"
                                delay={600}
                                duration={1500}
                            >
                                <Title>
                                    <span className="blue-and-underlined">
                                        {t('hero-title2')}
                                    </span>
                                </Title>
                            </Fade>
                        </Overflow>
                    </TextWrap>
                </Container>
            </Header>

            <VideoWrap>
                <Video />
                <VideoMobile />
            </VideoWrap>
            <LineWrap>
                <Line />
            </LineWrap>
        </>
    )
}

export default Hero
