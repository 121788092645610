import { media } from '@variables'
import styled from 'styled-components'

export const Wrap = styled.div`
    width: 100%;

    h3 {
        max-width: 715px;
        padding-bottom: 34px;

        ${media.tablet} {
            padding-bottom: 50px;
        }
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
        justify-content: space-between;
    }

    p {
        max-width: 100%;
        max-width: 360px;
        padding-bottom: 20px;

        ${media.tablet} {
            max-width: 340px;
            padding-bottom: 0px;
        }
        ${media.desktop} {
            max-width: 360px;
        }
    }
`
