import styled, { css } from 'styled-components'

import { colors, media } from '@variables'
import { AccessibleButton } from 'client/components/shared/styled'

export const Wrapper = styled.div<{ readonly isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: ${colors.primary.white};
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

    a {
        color: inherit;
        text-decoration: none;
    }

    h3 {
        text-align: center;
    }
`

export const Nav = styled.nav`
    width: 100%;
    display: flex;
    height: 100%;
    padding-top: 110px;
    flex-direction: column;
    color: ${colors.primary.black};

    ${media.custom(400)} {
        padding-top: 140px;
    }
`

export const Ul = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`

export const Li = styled.li`
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;

    :not(:last-of-type) {
        margin-bottom: 20px;
    }
`

export const SocialWrap = styled.div`
    width: 100%;
    display: flex;
    margin: 0 auto;
    max-width: 195px;
    padding: 70px 0;
    justify-content: space-between;
`

export const Langs = styled.div`
    display: flex;
    justify-content: center;
`

export const Lang = styled(AccessibleButton)<{ isActive: boolean }>`
    margin: 0 15px;
    color: ${colors.secondary.gray1};

    ${({ isActive }) =>
        isActive &&
        css`
            color: ${colors.primary.black};
        `}
`
