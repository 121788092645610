import React from 'react'

import Head from 'client/components/shared/Head'
import Hero from 'client/sections/Hero'
import Clients from 'client/sections/Clients'
import About from 'client/sections/About'
import Usp from 'client/sections/Usp'
import Team from 'client/sections/Team'
import Focus from 'client/sections/Focus'
import Portfolio from 'client/sections/Portfolio'
import Refocus from 'client/sections/Refocus'
import Pillars from 'client/sections/Pillars'
import InMedia from 'client/sections/InMedia'

const Home = () => {
    return (
        <>
            <Head />

            <Hero />
            <Clients />
            <About />
            <Usp />
            <Team />
            <Focus />
            <Portfolio />
            <Refocus />
            <Pillars />
            <InMedia />
        </>
    )
}

export default Home
