import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Routes } from '@variables'
import Social from 'client/components/shared/Social'
import Container from 'client/components/shared/Container'
import { Wrapper, Nav, Ul, Li, SocialWrap, Langs, Lang } from './styled'

type Props = {
    isOpen: boolean
    closeMobileMenu: () => void
}

const MobileMenu = ({ isOpen, closeMobileMenu }: Props) => {
    const [t, i18] = useTranslation()
    const { language } = i18

    return (
        <Wrapper isOpen={isOpen}>
            <Container>
                <Nav>
                    <Ul>
                        <Li>
                            <h3>
                                <NavLink
                                    to={Routes.HOME}
                                    onClick={closeMobileMenu}
                                >
                                    {t('nav-home')}
                                </NavLink>
                            </h3>
                        </Li>
                        <Li>
                            <h3>
                                <NavLink
                                    to={Routes.BLOG}
                                    onClick={closeMobileMenu}
                                >
                                    {t('nav-blog')}
                                </NavLink>
                            </h3>
                        </Li>
                        <Li>
                            <h3>
                                <NavLink
                                    to={Routes.CONTACTS}
                                    onClick={closeMobileMenu}
                                >
                                    {t('contact-us')}
                                </NavLink>
                            </h3>
                        </Li>
                    </Ul>
                    <SocialWrap>
                        <Social type="dribbble" />
                        <Social type="facebook" />
                        <Social type="github" />
                        <Social type="instagram" />
                        <Social type="linkedin" />
                    </SocialWrap>
                    {/* <Langs>
                        <Lang
                            isActive={language === 'cs'}
                            onClick={() => {
                                i18.changeLanguage('cs')
                                closeMobileMenu()
                            }}
                        >
                            CS
                        </Lang>
                        <Lang
                            isActive={language === 'en'}
                            onClick={() => {
                                i18.changeLanguage('en')
                                closeMobileMenu()
                            }}
                        >
                            EN
                        </Lang>
                    </Langs> */}
                </Nav>
            </Container>
        </Wrapper>
    )
}

export default MobileMenu
