import styled, { css } from 'styled-components'

import { transition, colors, media } from '@variables'

export const Nav = styled.nav<{
    readonly isVisible: boolean
    readonly isBkgFilled: boolean
    readonly isMobileMenuOpen: boolean
}>`
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    z-index: 2;
    position: fixed;
    overflow: visible;
    will-change: transform;
    transition: transform ease-in 0.3s;
    transform: ${(props) =>
        props.isVisible ? 'translateY(0px)' : 'translateY(-90px)'};

    ::before {
        transition: opacity ease-in 0.25s;
        will-change: opacity;
        position: absolute;
        display: block;
        z-index: 3;
        content: '';
        height: 100%;
        width: 100%;
        opacity: 0;
        top: 0;

        box-shadow: 0px 1px 8px 0px hsla(239, 81%, 64%, 0.1);
        background-color: #f8f5fd;
        background-repeat: no-repeat;
        background-image: radial-gradient(
                circle at bottom left,
                rgba(254, 236, 248, 0.3) 0%,
                rgba(255, 255, 255, 0) 50%,
                rgba(255, 255, 255, 0) 100%
            ),
            radial-gradient(
                circle at top right,
                rgba(254, 236, 248, 0.3) 0%,
                rgba(255, 255, 255, 0) 50%,
                rgba(255, 255, 255, 0) 100%
            ),
            radial-gradient(
                circle at right,
                rgba(90, 93, 238, 0.05) 0%,
                rgba(255, 255, 255, 0) 50%,
                rgba(255, 255, 255, 0) 100%
            );
    }

    ${({ isBkgFilled, isMobileMenuOpen }) =>
        isBkgFilled &&
        !isMobileMenuOpen &&
        css`
            :before {
                opacity: 1;
            }
        `}

    a {
        text-decoration: none;
        color: ${colors.primary.black};
    }
`

export const Logo = styled.div`
    left: 0;
    margin: auto;
    display: flex;
    max-width: 144px;
    max-height: 34px;
    overflow: hidden;
    position: relative;
    position: absolute;
    align-items: center;

    ${media.tablet} {
        right: 0;
    }

    a {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        ${media.tablet} {
            :hover {
                img {
                    transform: scale(0.95);
                }
            }
        }
    }

    img {
        width: 100%;
        height: auto;
        max-width: 144px;
        transition: ${transition.main};
    }
`

export const NavContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`

export const Menu = styled.ul`
    margin: 0;
    padding: 0;
    text-indent: 0;
    display: none;
    list-style-type: none;

    ${media.tablet} {
        display: inline-flex;
    }

    a {
        ${media.tablet} {
            :hover {
                color: ${colors.secondary.blue};
            }
        }
    }
`

// TODO: refactor css animation:
// animate with transform, opacity, before or after
export const Burger = styled.div<{ readonly isOpen: boolean }>`
    width: 40px;
    height: 40px;
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    align-items: center;
    will-change: opacity;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    margin-left: auto;
    position: relative;
    transition: ${transition.main};
    background-color: ${colors.primary.white};
    box-shadow: 0px 1px 1px rgba(90, 93, 238, 0.4);

    ::before {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        will-change: opacity;
        transition: ${transition.main};
    }

    ${(props) => props.isOpen && ` :before { opacity: 1; } `};

    ${media.tablet} {
        display: none;
    }

    span {
        width: 12px;
        height: 1px;
        border-radius: 4px;
        position: relative;
        will-change: transform opacity;
        transition: ${transition.main};
        background-color: ${colors.primary.black};

        ${(props) =>
            props.isOpen &&
            `
            transform-origin: 47%;

            :nth-of-type(1) {
                transform: rotate(45deg);
            }
            :nth-of-type(2) {
                transform: rotate(-45deg);
            }
            :last-of-type {
                opacity: 0;
            }
        `}

        ${(props) =>
            !props.isOpen && `:not(:last-of-type) { margin-bottom: 3px; }`}
    }
`

export const Right = styled.div`
    display: none;
    ${media.tablet} {
        display: flex;
    }
    button {
        margin-left: 34px;
    }
`
