import { isEmpty, isNil } from 'lodash'

export const getScrollTop = () => {
    if (typeof pageYOffset != 'undefined') {
        //most browsers except IE before #9
        return pageYOffset
    } else {
        const B = document.body //IE 'quirks'
        let D = document.documentElement //IE with doctype
        D = D.clientHeight ? D : B
        return D.scrollTop
    }
}

export const blockScroll = () => {
    document.body.classList.add('block-scroll')
}

export const unblockScroll = () => {
    document.body.classList.remove('block-scroll')
}

export const smoothScrollToEl = (id: string) => {
    const el = document.getElementById(id)
    if (!el) return
    const offset = el.offsetTop - 90
    window.scroll({
        top: offset,
        left: 0,
        behavior: 'smooth'
    })
}

export const shuffle = (a: TODO_ANY[]) => {
    for (let i = a.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[a[i], a[j]] = [a[j], a[i]]
    }
    return a
}

export const readingTime = (text: string | undefined) => {
    if (!text) return 0

    const wordsPerMinute = 200
    const noOfWords = text.split(/\s/g).length
    const minutes = noOfWords / wordsPerMinute
    const readTime = Math.ceil(minutes)
    return readTime
}

export const transformPostsFromServerToWriteIntoApolloCache = (
    data?: Post[]
) => {
    if (!data) return []

    return data.map((post: Post) => {
        let categories: Post['categories'] =
            !post?.categories || isEmpty(post?.categories)
                ? []
                : post.categories

        categories = [
            ...categories.map((category: { id: string; name: string }) => {
                return {
                    ...category,
                    __typename: 'PostCategory'
                }
            })
        ]

        const result = {
            ...post,
            categories,
            project: {
                ...(post?.project || {}),
                __typename: 'PostProject'
            },
            author: {
                ...(post?.author || {}),
                __typename: 'PostAuthor'
            },
            __typename: 'Post'
        }

        return result
    })
}
