import { media } from './../../../common/variables'
import styled from 'styled-components'

import { colors } from '@variables'

export const Wrap = styled.div`
    width: 100%;
    padding: 44px 0 0 0;

    ${media.tablet} {
        padding: 80px 0 0 0;
    }
    ${media.desktop} {
        padding: 135px 0 0 0;
    }

    h2 {
        color: ${colors.secondary.blue};
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
`

export const Col = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 240px;
    align-items: center;

    :not(:last-of-type) {
        margin-bottom: 40px;
    }

    ${media.tablet} {
        width: 20%;
        max-width: 260px;
        align-items: flex-start;

        :not(:last-of-type) {
            margin-bottom: 0px;
        }
    }

    p {
        @media (max-width: 853px) {
            line-height: 26px;
        }
    }
`

export const Line = styled.div`
    width: 100%;
    height: 1px;
    opacity: 0.15;
    background: #070512;
    margin: 12px 0 10px 0;
`
