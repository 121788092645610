// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LazyLoad from 'react-lazy-load'
import { Link } from 'react-router-dom'
import TextTruncate from 'react-text-truncate'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { Routes } from '@variables'
import { readingTime } from '@utils'
import {
    Ul,
    Li,
    Img,
    Text,
    Title,
    Header,
    Wrapper,
    TimeToRead,
    ReadMore
} from './styled'

type Props = {
    post: Post
    categories: string[]
    setActiveCategory?: (c: string) => void
}

const BlogPost = ({ post, categories, setActiveCategory }: Props) => {
    const [t] = useTranslation()

    const { slug, title, categories: articleCategories, mainImg } = post

    return (
        <Wrapper>
            <Link to={`${Routes.BLOG}/${slug}`}>
                <LazyLoad width={400} height={270} offset={1000}>
                    <Img src={mainImg} alt={title} loading="lazy" />
                </LazyLoad>
            </Link>

            <Header>
                <Ul>
                    {articleCategories.map((category) => (
                        <Li
                            key={category.id}
                            onClick={() =>
                                onClick(categories, category, setActiveCategory)
                            }
                        >
                            <h6>
                                <span>{category.name}</span>
                            </h6>
                        </Li>
                    ))}
                </Ul>
                <TimeToRead>
                    {readingTime(post.text)} {t('min-reading')}
                </TimeToRead>
            </Header>
            <Link to={`${Routes.BLOG}/${slug}`}>
                <Title title={title}>
                    <h5>
                        <TextTruncate
                            line={3}
                            element="span"
                            truncateText="…"
                            text={title}
                        />
                    </h5>
                </Title>
            </Link>
            <Text dangerouslySetInnerHTML={{ __html: post.text }} />
            <Link to={`${Routes.BLOG}/${slug}`}>
                <ReadMore>{t('read-more')}</ReadMore>
            </Link>
        </Wrapper>
    )
}

const onClick = (
    categories: string[],
    category: Post['categories'][0],
    setActiveCategory: Props['setActiveCategory']
) => {
    if (categories.includes(category.name.toLowerCase())) {
        !!setActiveCategory && setActiveCategory(category.name.toLowerCase())
    } else {
        !!setActiveCategory && setActiveCategory('others')
    }
}

export default BlogPost
