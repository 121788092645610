import styled, { keyframes, css } from 'styled-components'

import { media } from '@variables'
import VideoPlayIcon from 'client/components/SVG/VideoPlayLanding'

const VIDEO_PLAY_BUTTON_WIDTH_TABLET = 120
const VIDEO_PLAY_BUTTON_WIDTH_DESKTOP = 140
const VIDEO_PLAY_BUTTON_WIDTH_XL = 200

export const Wrap = styled.div<{ zIndex: number }>`
    display: none;

    ${media.tablet} {
        display: block;
        position: relative;
        height: 100vh;
        width: 100%;
    }
    ${media.desktop} {
        display: block;
        position: relative;
        z-index: ${(props) => props.zIndex};
    }
    ${media.xl} {
        display: block;
        position: relative;
    }
`

const MaskAnimationTablet = keyframes`
0% {
  mask-size: 130px;
  -webkit-mask-size: 130px;
}
70% {
  mask-size: 180px;
  -webkit-mask-size: 180px;
}
100% {
  mask-size: 240px;
  -webkit-mask-size: 240px;
}
`
const MaskAnimationDesktop = keyframes`
0% {
  mask-size: 240px;
  -webkit-mask-size: 240px;
}
70% {
  mask-size: 255px;
  -webkit-mask-size: 255px;
}
100% {
  mask-size: 280px;
  -webkit-mask-size: 280px;
}
`
const MaskAnimationXl = keyframes`
0% {
  mask-size: 310px;
  -webkit-mask-size: 310px;
}
70% {
  mask-size: 360px;
  -webkit-mask-size: 360px;
}
100% {
  mask-size: 410px;
  -webkit-mask-size: 410px;
}
`

const animationInterpolation = css`
    ${media.tablet} {
        -webkit-animation: 1s ${MaskAnimationTablet} ease;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }
    ${media.desktop} {
        -webkit-animation: 1s ${MaskAnimationDesktop} ease;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }
    ${media.xl} {
        -webkit-animation: 1s ${MaskAnimationXl} ease;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }
`

export const MaskWrap = styled.div<{ maskPath: string; canPlay?: boolean }>`
    height: 100%;
    opacity: 0;

    mask: ${(props) => `url(${props.maskPath})`};
    mask-repeat: no-repeat;
    mask-position: 50%;
    -webkit-mask: ${(props) => `url(${props.maskPath})`};
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: 50%;

    mask-size: 25000%;
    -webkit-mask-size: 25000%;

    ${(props) => (props.canPlay ? animationInterpolation : '')}
`

export const Video = styled.video`
    height: 100vh;
    width: 100vw;
    object-fit: cover;
`

export const FullScreenWrap = styled.div<{ isDisplayed?: boolean }>`
    visibility: ${(props) => (props.isDisplayed ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.isDisplayed ? '1' : '0.5')};
    transition: visibility 0s, opacity 0.25s linear;
    background-color: #000616;
    justify-content: center;
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 11;
`

export const VideoFulscreen = styled.video<{ isDisplayed?: boolean }>`
    width: ${(props) => (props.isDisplayed ? '60%' : '0%')};
    transition-duration: 0.1s;
`

export const ButtonClose = styled.button`
    border: none;
    background: transparent;
    position: absolute;
    top: 100px;
    right: 100px;
    display: inline-block;
    width: 40px;
    height: 40px;
    overflow: hidden;
`

export const Button = styled.button<{ inner: boolean }>`
    top: 10%;
    padding: 0;
    z-index: -1;
    border: none;
    display: block;
    background: none;
    position: absolute;
    background-position: center;
    transform: translate(50%, 50%);
    right: ${(props) => (props.inner ? '50%' : '45%')};

    ${media.tablet} {
        top: 40%;

        :hover {
            transform: translate(50%, 50%) scale(1.05);
        }
    }
`

export const ProgressBar = styled.div`
    height: 3px;
    border-radius: 5px;
    background-color: #f53f54;
`

export const ProgressUnderlay = styled.div`
    width: 100%;
    position: absolute;
    height: 3px;
    background-color: rgba(37, 202, 233, 0.2);
    border-radius: 5px;
    z-index: -1;
`

export const ProgressWrap = styled.div`
    position: absolute;
    left: 20%;
    bottom: 15%;
    width: 60%;
`

export const Input = styled.input`
    width: 100%;
    -webkit-appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    opacity: 0;
`

const VIDEO_PLAY_BUTTON_WIDTH_TABLET_DB2 = VIDEO_PLAY_BUTTON_WIDTH_TABLET / 2
const VIDEO_PLAY_BUTTON_WIDTH_DESKTOP_DB2 = VIDEO_PLAY_BUTTON_WIDTH_DESKTOP / 2
const VIDEO_PLAY_BUTTON_WIDTH_XL_DB2 = VIDEO_PLAY_BUTTON_WIDTH_XL / 2

const PlayButtonAppear = keyframes`
  from {
  }
  to {
    transform: scale(1);
  }
`

const playButtonAnimation = css`
    animation: ${PlayButtonAppear} 0.5s ease;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`

export const PlayButton = styled.button<{ canPlay: boolean }>`
    padding: 0;
    border: none;
    border-radius: 50%;
    background-color: #fffcfe;
    background-position: center;
    transition: background-color 0.5s linear;
    cursor: pointer;

    ${media.tablet} {
        position: absolute;
        transform: scale(0);
        width: ${VIDEO_PLAY_BUTTON_WIDTH_TABLET}px;
        height: ${VIDEO_PLAY_BUTTON_WIDTH_TABLET}px;
        top: calc(50% - ${VIDEO_PLAY_BUTTON_WIDTH_TABLET_DB2}px);
        left: calc(50% - ${VIDEO_PLAY_BUTTON_WIDTH_TABLET_DB2}px);
        ${(props) => (props.canPlay ? playButtonAnimation : '')}
    }
    ${media.desktop} {
        margin-top: 0;
        width: ${VIDEO_PLAY_BUTTON_WIDTH_DESKTOP}px;
        height: ${VIDEO_PLAY_BUTTON_WIDTH_DESKTOP}px;
        top: calc(50% - ${VIDEO_PLAY_BUTTON_WIDTH_DESKTOP_DB2}px);
        left: calc(50% - ${VIDEO_PLAY_BUTTON_WIDTH_DESKTOP_DB2}px);
    }
    ${media.xl} {
        width: ${VIDEO_PLAY_BUTTON_WIDTH_XL}px;
        height: ${VIDEO_PLAY_BUTTON_WIDTH_XL}px;
        top: calc(50% - ${VIDEO_PLAY_BUTTON_WIDTH_XL_DB2}px);
        left: calc(50% - ${VIDEO_PLAY_BUTTON_WIDTH_XL_DB2}px);
    }
`

export const VideoPlayLanding = styled(VideoPlayIcon)<{ scaled: number }>`
    transform: scale(${(props) => props.scaled});
    transition: transform 0.25s ease;
`

export const LoadingWrap = styled.div<{ hidden: boolean }>`
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    z-index: 10;
    opacity: 1;

    visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease-in;
    background: #fff;
`
