import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Top from 'client/sections/Contacts/Top'
import Head from 'client/components/shared/Head'
import Offices from 'client/sections/Contacts/Offices'
import Copyright from 'client/sections/Contacts/Copyright'
import { unblockScroll } from '@utils'

const Contacts = () => {
    const [t, i18n] = useTranslation()

    useEffect(() => {
        unblockScroll()
    }, [])

    return (
        <>
            <Head />
            <Top />
            <Offices />
            <Copyright />
        </>
    )
}

export default Contacts
