import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                fill="#070512"
                d="M19.023 4.758a7.405 7.405 0 01-2.128.583 3.711 3.711 0 001.628-2.047 7.433 7.433 0 01-2.35.897 3.702 3.702 0 00-6.307 3.376A10.511 10.511 0 012.234 3.7c-.32.548-.5 1.184-.5 1.862 0 1.283.652 2.417 1.646 3.082a3.699 3.699 0 01-1.678-.464v.046a3.707 3.707 0 002.969 3.631A3.703 3.703 0 013 11.918a3.71 3.71 0 003.458 2.572 7.42 7.42 0 01-5.479 1.532 10.459 10.459 0 005.674 1.663c6.81 0 10.533-5.64 10.533-10.531 0-.16-.004-.322-.01-.48a7.526 7.526 0 001.847-1.916z"
            ></path>
        </svg>
    )
}

export default Icon
