import React, { useEffect, useState } from 'react'

import { shuffle } from '@utils'
import Logo from '../LogoBar/Logo'
import { defaultAvailableImages } from '../LogoBar'
import {
    Wrap,
    RowWrap,
    LogosWrap,
    BorderVertical,
    BorderHorizontal
} from './styled'

const LogoBarMobile = () => {
    if (typeof window === 'undefined') return null

    const [usedImages, setUsedImages] = useState<string[]>([])
    const [availableImages, setAvailableImages] = useState(
        defaultAvailableImages
    )

    useEffect(() => {
        const newUsedImages = shuffle(availableImages).slice(0, 3)

        const a = new Set(defaultAvailableImages)
        const b = new Set(newUsedImages)
        const difference = new Set([...a].filter((x) => !b.has(x)))

        setUsedImages(newUsedImages)
        setAvailableImages(Array.from(difference))
    }, [])

    const requireImage = (key: string) => {
        const KEY = Number.parseInt(key)

        const availableImagesWithOldImage = [
            ...availableImages,
            usedImages[KEY]
        ]
        const newImage = availableImagesWithOldImage[0]
        const usableImages = [...usedImages]

        usableImages.splice(KEY, 1, availableImagesWithOldImage[0])
        availableImagesWithOldImage.shift()

        setUsedImages(usableImages)
        setAvailableImages(availableImagesWithOldImage)
    }

    return (
        <Wrap>
            <LogosWrap>
                <RowWrap>
                    <Logo index="3" logo={'csob'} fixed={true} />
                    <BorderVertical />
                    <Logo index="4" logo={'innogy'} fixed={true} />
                </RowWrap>
                <BorderHorizontal />
                <RowWrap>
                    <Logo index="5" logo={'nestle'} fixed={true} />
                    <BorderVertical />
                    <Logo
                        index="0"
                        logo={usedImages[0]}
                        requireImage={requireImage}
                    />
                </RowWrap>
                <BorderHorizontal />
                <RowWrap>
                    <Logo
                        index="1"
                        logo={usedImages[1]}
                        requireImage={requireImage}
                    />
                    <BorderVertical />
                    <Logo
                        index="2"
                        logo={usedImages[2]}
                        requireImage={requireImage}
                    />
                </RowWrap>
            </LogosWrap>
        </Wrap>
    )
}

export default LogoBarMobile
