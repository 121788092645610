import styled from 'styled-components'

import { colors, media, transition } from '@variables'

export const Row = styled.div`
    width: 100%;
    display: flex;
    padding: 140px 0;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
        padding: 265px 0 225px 0;
        justify-content: space-between;
    }
`

export const Col = styled.div`
    width: 100%;

    :first-of-type {
        margin-bottom: 68px;

        ${media.tablet} {
            margin-bottom: 0px;
            width: calc(100% - 520px);
        }
    }
    :last-of-type {
        display: flex;
        flex-wrap: wrap;
        max-width: 520px;
    }
`

export const Link = styled.a`
    width: 100%;
    max-width: 50%;

    ${media.phone} {
        max-width: 33.3%;
    }

    img {
        width: 100%;
        height: auto;
        max-width: 126px;
        transition: ${transition.main};

        ${media.phone} {
            max-width: 146px;
        }
    }

    ${media.tablet} {
        :hover {
            img {
                transform: scale(1.05);
            }
        }
    }
`
