import styled from 'styled-components'

import { media } from '@variables'

export const LogosWrap = styled.div`
    width: 100%;
    height: 301px;
    display: flex;
    align-items: center;
    flex-direction: column;
`

export const RowWrap = styled.div`
    width: 100%;
    height: 150px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 1px 1fr 1px 1fr 1px 1fr;
`

export const Wrap = styled.div`
    width: 100%;
    display: none;

    ${media.tablet} {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
    }
`

export const BorderVertical = styled.div`
    width: 1px;
    height: 100%;
    opacity: 0.2;
    background-color: #979797;
`

export const BorderHorizontal = styled.div`
    width: 100%;
    height: 1px;
    opacity: 0.2;
    background-color: #979797;
`
