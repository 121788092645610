import styled, { keyframes } from 'styled-components'

const blink = keyframes`
  0% {
    opacity: 0.09;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.09;
  }
`

export const Dot = styled.circle<{ delay: string }>`
    opacity: 0.09;
    animation: ${blink} 1.5s ease infinite;
    animation-delay: ${props => props.delay};
`
