import React from 'react'

type Props = {
    size: number | string
}

const VideoPlay = ({ size }: Props) => (
    <svg
        width={`${size}px`}
        height={`${size}px`}
        viewBox="0 0 80 80"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs />
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="home-copy-2" transform="translate(-851.000000, -408.000000)">
                <g id="head-area" transform="translate(79.000000, 238.000000)">
                    <g id="video" transform="translate(438.000000, 0.000000)">
                        <g
                            id="play-button"
                            transform="translate(334.000000, 170.000000)"
                        >
                            <circle
                                id="Oval-2"
                                fill="#FFFFFF"
                                cx="40"
                                cy="40"
                                r="40"
                            />
                            <polygon
                                id="Triangle"
                                fill="#000616"
                                transform="translate(42.500000, 40.000000) rotate(90.000000) translate(-42.500000, -40.000000) "
                                points="42.5 34 50 46 35 46"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default VideoPlay
