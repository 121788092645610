import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import Slider, { ResponsiveType } from 'react-multi-carousel'

import HypeImg from '@static/images/hype.webp'
import VaskyImg from '@static/images/vasky.webp'
import MindpaxImg from '@static/images/mindpax.webp'
import IWattImg from '@static/images/iWatt.webp'
import BitplusImg from '@static/images/bitplus.webp'
import DojoImg from '@static/images/dojo.webp'
import Q365Img from '@static/images/q365.webp'
import { Overflow } from 'client/components/shared/styled'
import Container from 'client/components/shared/Container'
import { Wrap, Top, Row, Col, Bottom, Tags, CarouselWrap } from './styled'

const Portfolio = () => {
    const [t] = useTranslation()

    return (
        <>
            <Container maxWidth={1270}>
                <Wrap>
                    <Top>
                        <Overflow>
                            <Fade direction="up" duration={1500} triggerOnce>
                                <h3>{t('portfolio-title')}</h3>
                            </Fade>
                        </Overflow>
                        <Fade duration={2500} triggerOnce>
                            <p>{t('portfolio-text')}</p>
                        </Fade>
                    </Top>
                    <Row>
                        {projects.map((p) => (
                            <Col key={p.name}>
                                <a
                                    href={p.url}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        src={p.img}
                                        alt={p.name}
                                        width="360"
                                        height="360"
                                        loading="lazy"
                                    />
                                    <Bottom>
                                        <h4>{p.name}</h4>
                                        <Tags>
                                            {p.tags.map((t) => (
                                                <h6 key={t}>{t}</h6>
                                            ))}
                                        </Tags>
                                    </Bottom>
                                </a>
                            </Col>
                        ))}
                    </Row>
                </Wrap>
            </Container>
            <CarouselWrap>
                <Slider
                    ssr
                    infinite
                    swipeable
                    partialVisible
                    arrows={false}
                    draggable={false}
                    responsive={responsive}
                >
                    {projects.map((project) => (
                        <Slide project={project} key={project.name} />
                    ))}
                </Slider>
            </CarouselWrap>
        </>
    )
}

type SlideProps = {
    project: typeof projects[0]
}

const Slide = ({ project }: SlideProps) => {
    return (
        <Col>
            <a href={project.url} target="_blank" rel="noreferrer noopener">
                <img
                    width="360"
                    height="360"
                    loading="lazy"
                    src={project.img}
                    alt={project.name}
                />
                <Bottom>
                    <h4>{project.name}</h4>
                    <Tags>
                        {project.tags.map((t) => (
                            <h6 key={t}>{t}</h6>
                        ))}
                    </Tags>
                </Bottom>
            </a>
        </Col>
    )
}

const responsive: ResponsiveType = {
    xs: {
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 36,
        breakpoint: { max: 480, min: 0 }
    },
    sm: {
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 80,
        breakpoint: { max: 854, min: 480 }
    },
    tablet: {
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 160,
        breakpoint: { max: 6000, min: 854 }
    }
}

const projects = [
    {
        img: BitplusImg,
        name: 'Bit+',
        tags: ['iOS, Android', 'Web', 'UX & UI Design'],
        url: 'http://bplus.qusion.com/'
    },
    {
        img: IWattImg,
        name: 'iWatt',
        tags: ['iOS, Android', 'UX & UI Design'],
        url: 'https://iwatt.qusion.com/'
    },
    {
        img: Q365Img,
        name: 'Q365',
        tags: ['iOS', 'UX & UI Design'],
        url: 'http://q365.qusion.com/'
    },
    {
        img: DojoImg,
        name: 'Dojo',
        tags: ['HW Development', 'Prototyping', 'UX & UI Design'],
        url: 'https://www.dojostory.com'
    },
    {
        img: MindpaxImg,
        name: 'MindPax',
        tags: ['UX & UI Design'],
        url: 'https://mindpax.qusion.com'
    },
    {
        img: VaskyImg,
        name: 'Vasky.cz',
        tags: ['UX & UI Design'],
        url: 'https://www.vasky.cz/cs'
    }
]

export default Portfolio
