import get from 'lodash/get'
import { useQuery } from 'react-apollo'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { cmsQueries } from '@variables'
import BlogList from 'client/sections/BlogList'
import Head from 'client/components/shared/Head'
import { unblockScroll } from '@utils'

const Blog = () => {
    const [t, i18n] = useTranslation()

    const { data } = useQuery(cmsQueries.POSTS, {
        onError: (err) => console.error(err.message)
    })

    useEffect(() => {
        unblockScroll()
    }, [])

    return (
        <>
            <Head />
            <BlogList data={get(data, 'posts', [])} />
        </>
    )
}

export default Blog
