import React from 'react'

const CloseIcon = (props: TODO_ANY) => (
    <div {...props}>
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="22.5" cy="2.5" r="2.5" fill="white" />
            <circle cx="22.5" cy="22.5" r="2.5" fill="white" />
            <circle cx="12.5" cy="12.5" r="2.5" fill="white" />
            <circle cx="7.5" cy="17.5" r="2.5" fill="white" />
            <circle cx="7.5" cy="7.5" r="2.5" fill="white" />
            <circle cx="17.5" cy="8.5" r="2.5" fill="white" />
            <circle cx="17.5" cy="17.5" r="2.5" fill="white" />
            <circle cx="2.5" cy="2.5" r="2.5" fill="white" />
            <circle cx="2.5" cy="22.5" r="2.5" fill="white" />
        </svg>
    </div>
)

export default CloseIcon
