import { media } from '@variables'
import styled from 'styled-components'

export const Wrap = styled.div`
    width: 100%;
    text-align: center;
    padding: 80px 0 64px 0;

    ${media.tablet} {
        padding: 135px 0 175px 0;
    }

    h5 {
        padding-bottom: 30px;
    }
`
