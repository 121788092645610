import styled from 'styled-components'

import { colors, media } from '@variables'
import { AccessibleButton } from 'client/components/shared/styled'

export const Wrap = styled.section`
    width: 100%;
    padding: 140px 0 100px 0;

    ${media.tablet} {
        padding: 200px 0 140px 0;
    }
`

export const Head = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const Category = styled.h6`
    color: ${colors.secondary.blue};

    span:not(:last-of-type) {
        padding-right: 10px;
    }
`

export const Title = styled.h3`
    padding: 20px 0;

    @media (max-width: 479px) {
        font-size: 30px;
        line-height: 36px;
    }
`

export const ReadingTime = styled.h6`
    color: ${colors.secondary.gray1};
`

export const Img = styled.img`
    width: 100%;
    height: auto;
    border-radius: 4px;
    margin: 30px 0 45px 0;
`

export const HeadWrap = styled.div`
    width: 100%;
    margin: 0 auto;
    max-width: 840px;
`

export const Bottom = styled(HeadWrap)``

export const Text = styled.div`
    width: 100%;
    margin: 0 auto;
    max-width: 840px;

    sup {
        line-height: 18px;
        font-size: 12px;
        display: block;
    }

    h1 {
        font-size: 40px;
        line-height: 48px;
        padding: 32px 0;
    }
    h2 {
        font-size: 36px;
        line-height: 42px;
        padding: 28px 0;
    }
    h3 {
        font-size: 32px;
        line-height: 38px;
        padding: 24px 0;
    }
    h4 {
        font-size: 30px;
        line-height: 36px;
        padding: 24px 0;
    }
    h5 {
        font-size: 28px;
        line-height: 32px;
        padding: 24px 0;
    }
    h6 {
        font-size: 24px;
        line-height: 30px;
        padding: 24px 0;
    }

    p {
        padding-bottom: 20px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
        margin: 0 auto;
        max-width: 740px;
        overflow-wrap: break-word;
    }

    li {
        padding-bottom: 16px;
    }

    figure {
        margin: 0;
        img {
            padding-bottom: 0;
        }
    }

    img,
    figure,
    iframe {
        overflow: hidden;
        border-radius: 4px;
        padding-bottom: 12px;

        @media (max-width: 959px) {
            width: 100% !important;
            height: auto !important;
        }
    }

    a {
        ${media.tablet} {
            :hover {
                text-decoration: underline;
            }
        }
    }

    ul,
    ol {
        margin-top: 0;
        padding-left: 40px;
    }
`

export const ShareWrapper = styled.div`
    order: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.phone} {
        order: 2;
        width: 110px;
        padding-bottom: 0;
    }
`

export const ShareButton = styled(AccessibleButton)`
    width: 50%;
    height: 36px;
    display: flex;
    max-width: 55px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.secondary.gray1};

    :last-child {
        border-left: none;
    }

    iframe {
        position: absolute !important;
        height: 100% !important;
        width: 100% !important;
        opacity: 0 !important;
        left: 0 !important;
        top: 0 !important;
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    padding: 80px 0 40px 0;
    flex-direction: column;

    ${media.phone} {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Author = styled.div`
    order: 2;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 0 0 0;

    ${media.phone} {
        order: 1;
        padding: 0;
        flex-direction: row;
        width: calc(100% - 110px);
    }

    div {
        text-align: center;

        ${media.phone} {
            padding-left: 30px;
            text-align: left;
        }
    }
    h6 {
        opacity: 0.5;
        padding: 30px 0 4px 0;

        ${media.phone} {
            padding: 0 0 4px 0;
        }
    }
`

export const Avatar = styled.img`
    width: 110px;
    height: 110px;
    border-radius: 50%;
    object-fit: cover;
`

export const Tags = styled.h6`
    width: 100%;
    opacity: 0.3;
    text-align: center;
    color: ${colors.secondary.gray1};

    ${media.phone} {
        text-align: left;
    }
`

export const PostsWrap = styled.div`
    width: 100%;
    display: flex;
    margin: 0 auto;
    max-width: 960px;
    align-items: center;
    flex-direction: column;

    h4 {
        padding: 55px 0 30px 0;

        ${media.tablet} {
            padding: 55px 0;
        }
    }
`
