import styled, { css } from 'styled-components'

import { colors, media, transition } from '@variables'

export const Wrap = styled.div`
    width: 100%;
    padding-top: 65px;

    ${media.desktop} {
        padding-top: 0px;
    }
`

export const Top = styled.div`
    max-width: 580px;

    ${media.tablet} {
        margin-left: 45px;
    }
    ${media.desktop} {
        margin-left: 90px;
    }

    p {
        padding: 30px 0 70px 0;

        ${media.desktop} {
            padding: 30px 0 90px 0;
        }
    }
`

export const Row = styled.div`
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    display: none;

    ${media.tablet} {
        display: flex;
    }
`

export const Col = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 24px 0 0;

    ${media.phone} {
        margin: 0 48px 40px 0;
    }
    ${media.tablet} {
        flex: 1 1 auto;
        margin: 0 0 40px 0;
    }
    ${media.desktop} {
        margin-bottom: 80px;
    }

    img {
        width: 100%;
        height: auto;
        transition: ${transition.main};

        @media (max-width: 853px) {
            pointer-events: none;
        }

        ${media.tablet} {
            max-width: 300px;
        }
        ${media.desktop} {
            max-width: 360px;
        }
    }

    a {
        text-decoration: none;
        color: ${colors.primary.black};

        ${media.tablet} {
            :hover {
                h4 {
                    color: ${colors.secondary.blue};
                }
                img {
                    transform: scale(1.05);
                }
            }
        }
    }
`

export const Bottom = styled.div`
    width: 100%;
    display: flex;
    padding-top: 28px;
    align-items: center;
    justify-content: space-between;

    ${media.tablet} {
        margin: 0 auto;
        max-width: 360px;
        padding-top: 36px;
    }
`

export const Tags = styled.div`
    display: none;
    align-items: flex-end;
    flex-direction: column;

    ${media.tablet} {
        display: flex;
    }
`

export const CarouselWrap = styled.div`
    width: 100%;
    display: block;
    padding-left: 15px;

    ${media.tablet} {
        display: none;
    }
`
