import React, { useEffect, useState } from 'react'

import Logo from './Logo'
import { shuffle } from '@utils'
import {
    LogosWrap,
    RowWrap,
    Wrap,
    BorderHorizontal,
    BorderVertical
} from './styled'

export const defaultAvailableImages = [
    'ask-mask',
    'bitstock',
    'concept',
    'knihy-dobrovsky',
    'patria-finance',
    'mindpax',
    'faceup',
    'hsfg',
    'colcr',
    'vse',
    'connect',
    'spicker'
]

const LogoBar = () => {
    if (typeof window === 'undefined') {
        return null
    }

    const [usedImages, setUsedImages] = useState<string[]>([])
    const [availableImages, setAvailableImages] = useState(
        defaultAvailableImages
    )

    useEffect(() => {
        const newUsedImages = shuffle(availableImages).slice(0, 5)

        const a = new Set(defaultAvailableImages)
        const b = new Set(newUsedImages)
        const difference = new Set([...a].filter(x => !b.has(x)))

        setUsedImages(newUsedImages)
        setAvailableImages(Array.from(difference))
    }, [])

    const requireImage = (key: string) => {
        const KEY = Number.parseInt(key)

        const availableImagesWithOldImage = [
            ...availableImages,
            usedImages[KEY]
        ]
        const newImage = availableImagesWithOldImage[0]
        const usableImages = [...usedImages]

        usableImages.splice(KEY, 1, availableImagesWithOldImage[0])
        availableImagesWithOldImage.shift()

        setUsedImages(usableImages)
        setAvailableImages(availableImagesWithOldImage)
    }

    return (
        <Wrap>
            <LogosWrap>
                <RowWrap>
                    <Logo index="5" logo={'csob'} fixed />
                    <BorderVertical />
                    <Logo index="6" logo={'innogy'} fixed />
                    <BorderVertical />
                    <Logo index="7" logo={'nestle'} fixed />
                    <BorderVertical />
                    <Logo
                        index="0"
                        logo={'air-bank'}
                        requireImage={requireImage}
                    />
                </RowWrap>
                <BorderHorizontal />
                <RowWrap>
                    <Logo
                        index="1"
                        logo={usedImages[1]}
                        requireImage={requireImage}
                    />
                    <BorderVertical />
                    <Logo
                        index="2"
                        logo={usedImages[2]}
                        requireImage={requireImage}
                    />
                    <BorderVertical />
                    <Logo
                        index="3"
                        logo={usedImages[3]}
                        requireImage={requireImage}
                    />
                    <BorderVertical />
                    <Logo
                        index="4"
                        logo={usedImages[4]}
                        requireImage={requireImage}
                    />
                </RowWrap>
            </LogosWrap>
        </Wrap>
    )
}

export default LogoBar
