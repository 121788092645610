import styled from 'styled-components'
import { media } from '@variables'

export const Header = styled.div`
    width: 100%;
    padding: 140px 0 0 0;

    ${media.tablet} {
        height: 100vh;
        padding: 0;
    }
`

export const VideoWrap = styled.div`
    ${media.tablet} {
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        position: absolute;
    }
`

export const TextWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    ${media.phone} {
        flex-direction: row;
    }

    ${media.tablet} {
        flex-direction: column;
        justify-content: center;
    }
`

export const Title = styled.h1`
    font-size: 56px;
    line-height: 60px;
    padding: 0 0 8px 10px;

    ${media.tablet} {
        padding: 0 0 10px 0;
    }
    ${media.xl} {
        font-size: 91px;
        line-height: 104px;
    }
`

export const LineWrap = styled.div`
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    display: flex;

    ${media.tablet} {
        margin-top: 0px;
        /* Centered */
        position: absolute;
        margin-right: auto;
        margin-left: auto;
        bottom: 60px;
        width: 5px;
        right: 0;
        left: 0;
    }
`
