import { media } from '@variables'
import styled from 'styled-components'

export const Wrap = styled.div`
    width: 100%;
    padding: 150px 0;

    ${media.desktop} {
        padding: 180px 0;
    }

    h2 {
        text-align: center;
    }
`
