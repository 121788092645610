import styled from 'styled-components'

import { media, colors } from '@variables'

export const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;

    ${media.phone} {
        width: 400px;
    }

    a {
        width: 100%;
        color: inherit;
        display: block;
        text-decoration: none;

        div {
            max-width: 100%;
        }
    }
`

export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
`

export const Header = styled.div`
    width: 100%;
    align-items: center;
    display: inline-flex;
    padding: 30px 0 12px 0;
    justify-content: flex-start;

    ${media.desktop} {
        padding: 40px 0 16px 0;
    }
`

export const Ul = styled.ul`
    margin: 0;
    padding: 0;
    display: inherit;
`

export const Li = styled.li`
    opacity: 0.8;
    display: inline;
    list-style: none;
    padding-right: 15px;
`

export const TimeToRead = styled.h6`
    display: inline;
    letter-spacing: 0.1em;
    text-transform: uppercase;
`

export const Title = styled.a`
    margin: 0;
    cursor: pointer;
    text-decoration: none;

    h5 {
        padding-bottom: 20px;
    }

    ${media.tablet} {
        :hover {
            text-decoration: underline;
        }
    }
`

export const Text = styled.div`
    height: 90px;
    overflow: hidden;
    margin-bottom: 40px;
    color: ${colors.secondary.gray1};
`

export const ReadMore = styled.h6`
    padding-left: 15px;
    position: relative;

    :before {
        left: 0;
        top: 5px;
        width: 5px;
        height: 5px;
        opacity: 0.8;
        content: ' ';
        position: absolute;
        border-radius: 50%;
        background-color: ${colors.primary.black};
    }
`
