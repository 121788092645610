import anime from 'animejs'
import React, { useEffect, useState, useRef } from 'react'

import { LogoImage } from './styled'
import AirBankImg from '@static/images/clients/air-bank.png'
import AskMaskImg from '@static/images/clients/ask-mask.png'
import BitstockImg from '@static/images/clients/bitstock.png'
import ColcrImg from '@static/images/clients/colcr.png'
import ConceptImg from '@static/images/clients/concept.png'
import CsobImg from '@static/images/clients/csob.png'
import InnogyImg from '@static/images/clients/innogy.png'
import NestleImg from '@static/images/clients/nestle.png'
import FaceupImg from '@static/images/clients/faceup.png'
import HsfgImg from '@static/images/clients/hsfg.png'
import DobrovskyImg from '@static/images/clients/knihy-dobrovsky.png'
import MindpaxImg from '@static/images/clients/mindpax.png'
import PatriaImg from '@static/images/clients/patria-finance.png'
import SpickerImg from '@static/images/clients/spicker.png'
import ConnectImg from '@static/images/clients/connect.png'
import VseImg from '@static/images/clients/vse.png'

type Props = {
    index: string
    logo?: string
    fixed?: boolean
    requireImage?: (key: string) => void
}

const Logo = ({ logo, index, fixed, requireImage }: Props) => {
    if (logo === undefined) {
        return null
    }

    const logoRef = useRef(null)
    const [clientLogo, setClientLogo] = useState(logo)

    useEffect(() => {
        anime({
            targets: logoRef.current,
            easing: 'linear',
            duration: 250,
            opacity: 0,
            complete() {
                setClientLogo(logo)
                anime({
                    targets: logoRef.current,
                    easing: 'linear',
                    duration: 250,
                    opacity: 1
                })
            }
        })
    }, [logo])

    const onClick = () => {
        if (!fixed && requireImage) {
            requireImage(index)
        }
    }

    return (
        <LogoImage
            ref={logoRef}
            onClick={onClick}
            key={`logo_${index}`}
            clientLogo={getClientLogo(clientLogo)}
        />
    )
}

const getClientLogo = (name: string) => {
    if (name === 'ask-mask') {
        return AskMaskImg
    }
    if (name === 'bitstock') {
        return BitstockImg
    }
    if (name === 'concept') {
        return ConceptImg
    }
    if (name === 'knihy-dobrovsky') {
        return DobrovskyImg
    }
    if (name === 'patria-finance') {
        return PatriaImg
    }
    if (name === 'mindpax') {
        return MindpaxImg
    }
    if (name === 'faceup') {
        return FaceupImg
    }
    if (name === 'hsfg') {
        return HsfgImg
    }
    if (name === 'colcr') {
        return ColcrImg
    }
    if (name === 'vse') {
        return VseImg
    }
    if (name === 'connect') {
        return ConnectImg
    }
    if (name === 'connect') {
        return ConnectImg
    }
    if (name === 'spicker') {
        return SpickerImg
    }
    if (name === 'air-bank') {
        return AirBankImg
    }
    if (name === 'csob') {
        return CsobImg
    }
    if (name === 'innogy') {
        return InnogyImg
    }

    return NestleImg
}

export default Logo
