import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    margin: 0 auto;
    align-items: center;
    flex-direction: column;

    h1 {
        margin-bottom: 30px;
    }
`
