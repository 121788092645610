/* eslint-disable @typescript-eslint/ban-ts-comment */
import anime from 'animejs'
import React, { Component, createRef } from 'react'

import Loading from 'client/components/SVG/Logo'
import MiniVideo from '@static/video/mini-video.mp4'
import CloseIcon from 'client/components/SVG/CloseIcon'
import MobileVideo from '@static/video/qusion-mobile.mp4'
import QMaskNoCenter from '@static/images/q-mask-no-center.svg'
import VideoPlayButton from 'client/components/SVG/VideoPlayButton'
import {
    Wrap,
    Video,
    Input,
    Button,
    MaskWrap,
    PlayButton,
    ButtonClose,
    ProgressBar,
    LoadingWrap,
    ProgressWrap,
    FullScreenWrap,
    VideoFulscreen,
    ProgressUnderlay,
    VideoPlayLanding
} from './styled'
import { blockScroll, unblockScroll } from '@utils'

type State = TODO_ANY
type Props = TODO_ANY

class VideoBox extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            zIndex: 11,
            show: false,
            percentage: 0,
            paused: false,
            display: false,
            videoLoaded: false,
            loadingDone: false,
            playButtond: false
        }

        this.mediaPlayer = null

        this.videoFullscreen = createRef()
        this.playButton = createRef()
        this.maskWrap = createRef()
        this.loading = createRef()
    }

    videoFullscreen: TODO_ANY
    mediaPlayer: TODO_ANY
    playButton: TODO_ANY
    maskWrap: TODO_ANY
    loading: TODO_ANY

    componentWillUnmount() {
        document.removeEventListener('keydown', this.closeVideo)
        this.videoFullscreen.current.removeEventListener(
            'timeupdate',
            this.updateProgressBar
        )
    }

    componentDidMount() {
        // Run even if the video does not load
        setTimeout(() => !this.state.videoLoaded && this.playAnimation(), 3000)
    }

    setVideoLoaded = () => {
        this.setState({
            videoLoaded: true
        })
    }

    setLoadingDone = () => {
        this.setState({ loadingDone: true })
        let timeout = 500
        if (window.innerWidth < 854) {
            timeout = 0
        }

        setTimeout(() => {
            unblockScroll()
            this.setState({ zIndex: 0 })
        }, timeout)
    }

    handleVideoShow = () => {
        this.setState({ zIndex: 13 }, () => {
            this.mediaPlayer = document.getElementById('video-fullscreen')
            blockScroll()
            this.mediaPlayer.classList.add('isMostUp')
            this.setState(
                {
                    display: true,
                    paused: false
                },
                () => this.setState({ show: true })
            )
            document.addEventListener('keydown', this.closeVideo, false)
            this.videoFullscreen.current.addEventListener(
                'timeupdate',
                this.updateProgressBar,
                false
            )
            this.videoFullscreen.current.play()
        })
    }

    handleVideoClose = () => {
        this.setState({ zIndex: 0 })
        document.body.classList.remove('block-scroll')
        const video = document.getElementById('video-fullscreen')
        this.setState(
            {
                display: false
            },
            () =>
                this.setState({
                    show: false,
                    paused: true
                })
        )
        document.removeEventListener('keydown', this.closeVideo)
        this.mediaPlayer.removeEventListener(
            'timeupdate',
            this.updateProgressBar
        )
        // @ts-ignore
        video?.pause()
    }

    closeVideo = (event: TODO_ANY) => {
        if (event.keyCode === 27) {
            this.handleVideoClose()
        }
    }

    updateProgressBar = () => {
        const percentage = Math.floor(
            (100 / this.mediaPlayer.duration) * this.mediaPlayer.currentTime
        )
        this.setState({ percentage })
    }

    togglePlayPause = () => {
        if (this.mediaPlayer.paused) {
            this.mediaPlayer.play()
            this.setState({
                paused: false
            })
        } else {
            this.mediaPlayer.pause()
            this.setState({
                paused: true
            })
        }
    }

    handleProgressChange = (newPercentage: TODO_ANY) => {
        this.mediaPlayer.currentTime =
            (this.mediaPlayer.duration * newPercentage) / 100
        this.setState({
            percentage: newPercentage
        })
    }

    playAnimation = () => {
        const { setLoadingDone } = this
        const timeline = anime.timeline({ easing: 'linear', loop: false })

        let isDesktop = false
        if (window.innerWidth >= 854) {
            isDesktop = true
        }

        timeline
            .add({
                targets: this.maskWrap.current,
                duration: isDesktop ? 100 : 100,
                delay: 100,
                opacity: 1,
                begin() {
                    window.scrollTo(0, 0)
                }
            })
            .add({
                targets: this.loading.current,
                duration: isDesktop ? 100 : 500,
                opacity: 0,
                complete() {
                    setLoadingDone()
                }
            })
            .play()
    }

    videoCloseHandler = (event: TODO_ANY) => {
        if (event.target.id === 'video-full-wrap') {
            this.handleVideoClose()
        } else if (event.target.id === 'video-fullscreen') {
            this.togglePlayPause()
        } else {
            this.handleVideoClose()
        }
    }

    render() {
        const { display, show, paused, zIndex } = this.state

        return (
            <React.Fragment>
                <LoadingWrap ref={this.loading} hidden={this.state.loadingDone}>
                    <Loading />
                </LoadingWrap>
                <Wrap zIndex={zIndex} {...this.props}>
                    <MaskWrap
                        ref={this.maskWrap}
                        maskPath={QMaskNoCenter}
                        canPlay={this.state.loadingDone}
                    >
                        <Video
                            onLoadedData={() => {
                                this.setVideoLoaded()
                                this.playAnimation()
                            }}
                            preload="auto"
                            playsInline
                            autoPlay
                            muted
                            loop
                        >
                            <track kind="captions" />
                            <source src={MiniVideo} />
                            Your browser does not support HTML5 video.
                        </Video>
                    </MaskWrap>

                    <FullScreenWrap
                        isDisplayed={display}
                        id="video-full-wrap"
                        onMouseDown={this.videoCloseHandler}
                    >
                        <ButtonClose onClick={this.handleVideoClose}>
                            <CloseIcon />
                        </ButtonClose>
                        {paused ? (
                            <Button onClick={this.togglePlayPause} inner>
                                <VideoPlayButton size={130} />
                            </Button>
                        ) : (
                            ''
                        )}
                        <VideoFulscreen
                            loop
                            playsInline
                            // show={show}
                            id="video-fullscreen"
                            isDisplayed={display}
                            ref={this.videoFullscreen}
                        >
                            <track kind="captions" />
                            <source src={MobileVideo} type="video/mp4" />
                            Your browser does not support HTML5 video.
                        </VideoFulscreen>

                        <ProgressWrap>
                            <ProgressUnderlay />
                            <ProgressBar
                                style={{ width: `${this.state.percentage}%` }}
                            />
                            <Input
                                onChange={(e) =>
                                    this.handleProgressChange(e.target.value)
                                }
                                id="progress-bar"
                                type="range"
                                min="0"
                                max="100"
                            />
                        </ProgressWrap>
                    </FullScreenWrap>

                    <PlayButton
                        id="play-button"
                        ref={this.playButton}
                        onClick={this.handleVideoShow}
                        canPlay={this.state.loadingDone}
                        onMouseEnter={() =>
                            this.setState({ playButtond: true })
                        }
                        onMouseLeave={() =>
                            this.setState({ playButtond: false })
                        }
                    >
                        <VideoPlayLanding
                            style={{
                                top: 2,
                                position: 'relative'
                            }}
                            scaled={this.state.playButtond ? 1.2 : 1}
                        />
                    </PlayButton>
                </Wrap>
            </React.Fragment>
        )
    }
}

export default VideoBox
