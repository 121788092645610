import styled from 'styled-components'

import { media } from '@variables'

export const Wrap = styled.div`
    width: auto;
    height: auto;
    position: relative;
    padding-top: 48px;

    ${media.tablet} {
        display: none;
    }
`

export const Video = styled.video`
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    margin: 0 auto;
    width: calc(100% - 30px);
`

export const Button = styled.button`
    position: absolute;
    background: none;
    border: none;
    padding: 0;
    margin: 0;

    width: 130px;
    height: 130px;
    top: calc(50% - 40px);
    left: calc(50% - 65px);
`

export const LoadingWrap = styled.div<{ hidden: boolean }>`
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    z-index: 10;
    opacity: 1;

    visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #fff;
`
