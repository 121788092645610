import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'

import LogoBar from './LogoBar'
import { Wrap } from './styled'
import LogoBarMobile from './LogoBarMobile'
import Container from 'client/components/shared/Container'

const Clients = () => {
    const [t] = useTranslation()
    return (
        <Container maxWidth={1090}>
            <Wrap>
                <Fade triggerOnce duration={2500}>
                    <h5>{t('clients-title')}</h5>
                </Fade>
                <LogoBar />
                <LogoBarMobile />
            </Wrap>
        </Container>
    )
}

export default Clients
