import React from 'react'
import { useTranslation } from 'react-i18next'

import { Wrap, Row, Col, Line } from './styled'
import Counter from 'client/components/Counter'
import Container from 'client/components/shared/Container'

const Usp = () => {
    const [t] = useTranslation()
    return (
        <Wrap>
            <Container maxWidth={1130}>
                <Row>
                    <Col>
                        <h2>
                            <Counter head={0} tail={1} duration={2} />
                            M+
                        </h2>
                        <Line />
                        <p>{t('usp-1')}</p>
                    </Col>
                    <Col>
                        <h2>
                            <Counter head={2} tail={20} duration={2} />+
                        </h2>
                        <Line />
                        <p>{t('usp-2')}</p>
                    </Col>
                    <Col>
                        <h2>
                            <Counter head={10} tail={60} duration={2} />+
                        </h2>
                        <Line />
                        <p>{t('usp-3')}</p>
                    </Col>
                    <Col>
                        <h2>
                            <Counter head={0} tail={2} duration={2} />
                        </h2>
                        <Line />
                        <p>{t('usp-4')}</p>
                    </Col>
                </Row>
            </Container>
        </Wrap>
    )
}

export default Usp
