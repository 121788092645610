import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'

import Empty from './Empty'
import BlogPost from './BlogPost'
import { categories, Routes } from '@variables'
import { Row, Col, ButtonWrapper } from './styled'
import Loader from 'client/components/shared/Loader'
import Button from 'client/components/shared/Button'
import Post from 'client/pages/post'

export enum PostType {
    'POST' = 'POST',
    'NONE' = 'NONE'
}

type Props = {
    posts: Post[]
    limit: number
    type: PostType
    category?: string
    setCategory?: (c: string) => void
}

const Posts = ({ limit, category, setCategory, type, posts }: Props) => {
    const [t] = useTranslation()

    const [filteredPosts, setFiltredPosts] = useState<Post[]>([])
    const [allFilteredPosts, setAllFilteredPosts] = useState<Post[]>([])

    const filterPosts = () => {
        if (!posts) {
            return
        }

        const filteredPosts = posts.filter((post) =>
            filterByCategory(post, category)
        )

        setAllFilteredPosts(filteredPosts)
        setFiltredPosts(
            filteredPosts.slice(
                0,
                filteredPosts.length > limit ? limit : filteredPosts.length
            )
        )
    }

    useEffect(() => {
        filterPosts()
    }, [posts, category])

    if (!filteredPosts) {
        return <Loader />
    }
    if (!posts) {
        return null
    }

    return (
        <>
            <Row>
                <Empty
                    count={posts.length}
                    setCategory={setCategory}
                    show={filteredPosts.length === 0}
                />
                {filteredPosts.map((post) => (
                    <Col key={post.id} special={type === PostType.NONE}>
                        <BlogPost
                            post={post}
                            categories={categories}
                            setActiveCategory={setCategory}
                        />
                    </Col>
                ))}
            </Row>

            {filteredPosts.length < allFilteredPosts.length && (
                <ButtonWrapper
                    expanded={filteredPosts.length >= allFilteredPosts.length}
                >
                    <Button
                        to={type === PostType.POST ? Routes.BLOG : undefined}
                        onClick={
                            type === PostType.NONE
                                ? () => setFiltredPosts(allFilteredPosts)
                                : undefined
                        }
                    >
                        {t('learn-more')}
                    </Button>
                </ButtonWrapper>
            )}
        </>
    )
}

const filterByCategory = (post: Post, activeCategory?: string) => {
    if (activeCategory === 'all' || !activeCategory) {
        return true
    } else if (activeCategory === 'others') {
        return post.categories.some(
            (category) => !categories.includes(category.name.toLowerCase())
        )
    } else {
        return post.categories.some(
            (category) =>
                category.name.toLowerCase() === activeCategory?.toLowerCase()
        )
    }
}

export default Posts
