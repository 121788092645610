import styled from 'styled-components'

import { colors, media } from '@variables'

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 125px 0 40px 0;

    ${media.phone} {
        padding: 140px 0 70px 0;
    }

    ${media.tablet} {
        flex-direction: row;
        padding: 200px 0;
        justify-content: space-between;
    }
`

export const Col = styled.div`
    display: flex;
    flex-direction: column;

    :first-of-type {
        padding: 0 0 70px 0;
    }

    :last-of-type {
        max-width: 520px;
    }

    :first-of-type,
    :last-of-type {
        width: 100%;
    }

    ${media.tablet} {
        :first-of-type {
            width: 30%;
        }
        :last-of-type {
            width: 70%;
        }
    }
    ${media.desktop} {
        :first-of-type,
        :last-of-type {
            width: 50%;
        }
    }
`

export const ContactRow = styled.div`
    width: 100%;
    display: flex;

    flex-direction: column;

    ${media.phone} {
        flex-direction: row;
        justify-content: space-between;
    }
    /* ${media.desktop} {
        padding-bottom: 50px;
    } */
`

export const ContactCol = styled.div`
    display: flex;
    margin-bottom: 55px;
    flex-direction: column;

    ${media.phone} {
        width: 50%;
        max-width: 200px;
    }

    h6 {
        padding-bottom: 10px;
    }

    p {
        padding-bottom: 8px;
    }

    button {
        margin-top: 14px;
    }

    a {
        color: inherit;
        text-decoration: none;

        ${media.tablet} {
            :hover {
                color: ${colors.secondary.blue};
            }
        }
    }
`
