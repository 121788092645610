import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { readingTime } from '@utils'
import { Routes } from '@variables'
import Facebook from 'client/components/SVG/Social/fb'
import Twitter from 'client/components/SVG/Social/twitter'
import Posts, { PostType } from 'client/components/Posts'
import Container from 'client/components/shared/Container'
import {
    Wrap,
    Head,
    Category,
    Title,
    Img,
    Text,
    ReadingTime,
    HeadWrap,
    Bottom,
    ShareWrapper,
    ShareButton,
    Author,
    Avatar,
    Tags,
    Row,
    PostsWrap
} from './styled'

type Props = {
    post?: Post
    posts: Post[]
}

const BlogPost = ({ post, posts }: Props) => {
    const [t, i18] = useTranslation()

    if (!post) {
        return null
    }

    const date = dayjs(post.createdAt).format('MMMM DD, YYYY')
    const categories = post.categories.map((item) => item.name)

    return (
        <Container maxWidth={1100}>
            <Wrap>
                <HeadWrap>
                    <Head>
                        <div>
                            <h6>{date}</h6>
                        </div>
                        <div>
                            <Category>
                                {categories.map((category) => (
                                    <span key={category}>{category}</span>
                                ))}
                            </Category>
                        </div>
                    </Head>
                    <Title>{post.title}</Title>
                    <ReadingTime>
                        {readingTime(post.text)} {t('min-reading')}
                    </ReadingTime>
                </HeadWrap>
                <Img src={post.mainImg} alt={post.title} loading="lazy" />
                <Text dangerouslySetInnerHTML={{ __html: post.text }} />
                <Bottom>
                    <Row>
                        <ShareWrapper>
                            <ShareButton
                                title="share via twitter"
                                style={{ position: 'relative' }}
                            >
                                <Twitter />
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    style={shareLinkStyle}
                                    href="https://twitter.com/intent/tweet"
                                />
                            </ShareButton>
                            <ShareButton title="share via facebook">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ height: 20 }}
                                    className="fb-xfbml-parse-ignore"
                                    href={`https://www.facebook.com/sharer/sharer.php?u=https://qusion.com/${Routes.BLOG}/${post.slug}`}
                                >
                                    <Facebook />
                                </a>
                            </ShareButton>
                        </ShareWrapper>

                        <Author>
                            <Avatar
                                width="110"
                                height="110"
                                loading="lazy"
                                alt={post?.author?.name || ''}
                                src={post?.author?.profileImg || ''}
                            />
                            <div>
                                <h6>{t('author')}</h6>
                                <h5 className="blue">
                                    {post?.author?.name || t('author')}
                                </h5>
                            </div>
                        </Author>
                    </Row>
                    {post?.tags && (
                        <Tags>
                            {t('tags')}: {post.tags}
                        </Tags>
                    )}
                </Bottom>

                <PostsWrap>
                    <h4>{t('keep-reading')}</h4>
                    <Posts posts={posts} limit={4} type={PostType.POST} />
                </PostsWrap>
            </Wrap>
        </Container>
    )
}

const shareLinkStyle = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0,
    left: 0,
    top: 0
} as React.CSSProperties

export default BlogPost
