import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Mousewheel, Keyboard } from 'swiper'

import { Wrap } from './styled'
import Img1 from '@static/images/carousel1.webp'
import Img2 from '@static/images/carousel2.webp'
import Img3 from '@static/images/carousel3.webp'
import Img4 from '@static/images/carousel4.webp'

SwiperCore.use([Mousewheel, Keyboard])

const Team = () => {
    return (
        <Wrap>
            <Swiper loop cssMode keyboard mousewheel slidesPerView="auto">
                {data.map((_, idx) => (
                    <SwiperSlide key={`${idx}-slide`}>
                        <img
                            src={_.src}
                            alt={_.alt}
                            width="624"
                            height="624"
                            loading="lazy"
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Wrap>
    )
}

const data = [
    {
        src: Img1,
        alt: 'Team member'
    },
    {
        src: Img2,
        alt: 'Team member'
    },
    {
        src: Img3,
        alt: 'Team member'
    },
    {
        src: Img4,
        alt: 'Team member'
    }
]

export default Team
