import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="42"
            fill="none"
            viewBox="0 0 41 42"
            {...props}
        >
            <g filter="url(#filter0_d_3555_11915)">
                <rect width="39" height="40" x="1" fill="#fff" rx="19.5"></rect>
            </g>
            <path fill="#070512" d="M25 20l-6 3.464v-6.928L25 20z"></path>
            <defs>
                <filter
                    id="filter0_d_3555_11915"
                    width="41"
                    height="42"
                    x="0"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="1"></feOffset>
                    <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.352941 0 0 0 0 0.364706 0 0 0 0 0.933333 0 0 0 0.4 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3555_11915"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_3555_11915"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    )
}

export default Icon
