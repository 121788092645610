import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'

import { Routes } from '@variables'
import Button from 'client/components/shared/Button'
import Container from 'client/components/shared/Container'
import { Row, Col, ContactCol, ContactRow } from './styled'
import { Overflow } from 'client/components/shared/styled'

const ContactsTop = () => {
    const [t, i18n] = useTranslation()

    return (
        <Container maxWidth={1070}>
            <Row>
                <Col>
                    <h1>
                        <Overflow>
                            <Fade direction="up" duration={1500} triggerOnce>
                                <span>{t('contacts-title1')} </span>
                            </Fade>
                        </Overflow>
                        <Overflow style={{ padding: '0 0 10px 0' }}>
                            <Fade
                                direction="up"
                                duration={1500}
                                triggerOnce
                                delay={200}
                            >
                                <span className="blue-and-underlined">
                                    {t('contacts-title2')}
                                </span>
                            </Fade>
                        </Overflow>
                    </h1>
                </Col>
                <Col>
                    <ContactRow>
                        <ContactCol>
                            <Overflow>
                                <Fade
                                    direction="up"
                                    duration={1500}
                                    triggerOnce
                                >
                                    <h6 className="blue">
                                        {t('new-business')}
                                    </h6>
                                </Fade>
                            </Overflow>
                            <Fade triggerOnce duration={2500}>
                                <p>
                                    <a href="mailto:work@qusion.com">
                                        work@qusion.com
                                    </a>
                                </p>
                            </Fade>
                            <Fade triggerOnce duration={2500} delay={150}>
                                <p>
                                    (CZ){' '}
                                    <a href="tel:+420605764690">
                                        +420 605 764 690
                                    </a>
                                </p>
                            </Fade>
                            <Fade triggerOnce duration={2500} delay={300}>
                                <p>
                                    (USA){' '}
                                    <a href="tel:+14156056955">
                                        +1 (415) 605 6955
                                    </a>
                                </p>
                            </Fade>
                        </ContactCol>
                        <ContactCol>
                            <Overflow>
                                <Fade
                                    direction="up"
                                    duration={1500}
                                    triggerOnce
                                >
                                    <h6 className="blue">{t('join-us')}</h6>
                                </Fade>
                            </Overflow>
                            <Fade triggerOnce duration={2500}>
                                <p>
                                    <a href="mailto:career@qusion.com">
                                        career@qusion.com
                                    </a>
                                </p>
                            </Fade>
                            <Fade triggerOnce duration={2500} delay={150}>
                                <Button variant="ARROW" href={Routes.CAREER}>
                                    {t('careers')}
                                </Button>
                            </Fade>
                        </ContactCol>
                    </ContactRow>
                    <ContactRow>
                        <ContactCol>
                            <Overflow>
                                <Fade
                                    direction="up"
                                    duration={1500}
                                    triggerOnce
                                >
                                    <h6 className="blue">{t('press')}</h6>
                                </Fade>
                            </Overflow>
                            <Fade triggerOnce duration={2500}>
                                <p>
                                    <a href="mailto:tereza@qusion.com">
                                        tereza@qusion.com
                                    </a>
                                </p>
                            </Fade>
                            <Fade delay={150} triggerOnce duration={2500}>
                                <Button variant="ARROW" href={Routes.PRESS_KIT}>
                                    {t('press-kit')}
                                </Button>
                            </Fade>
                        </ContactCol>
                    </ContactRow>
                </Col>
            </Row>
        </Container>
    )
}

export default ContactsTop
