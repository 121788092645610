import Rellax from 'rellax'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef } from 'react'

import { Row, Col, Pillar, ImgWrap } from './styled'
import RightImg from '@static/images/pillars-left.webp'
import LeftImg from '@static/images/pillars-right.webp'
import Container from 'client/components/shared/Container'
import { Overflow } from 'client/components/shared/styled'

const Pillars = () => {
    const [t] = useTranslation()

    const leftImgRef = useRef<TODO_ANY>()
    const rightImgRef = useRef<TODO_ANY>()

    useEffect(() => {
        const rellax1 = new Rellax(leftImgRef.current, {
            speed: 2,
            vertical: true,

            horizontal: false,
            relativeToWrapper: true,
            wrapper: '#pillars-img-wrapper'
        })
        const rellax2 = new Rellax(rightImgRef.current, {
            speed: -2,
            vertical: true,
            horizontal: false,
            relativeToWrapper: true,
            wrapper: '#pillars-img-wrapper'
        })

        return () => {
            rellax1.destroy()
            rellax2.destroy()
        }
    }, [])

    return (
        <Container maxWidth={1210}>
            <Row>
                <Col>
                    <Overflow className="pillars-title">
                        <Fade triggerOnce duration={1500} direction="up">
                            <h3>{t('pillars-title')}</h3>
                        </Fade>
                    </Overflow>
                </Col>
                <Col>
                    <Pillar>
                        <Fade triggerOnce duration={2500}>
                            <h2 className="blue">01</h2>
                        </Fade>
                        <div>
                            <Fade triggerOnce duration={2500} delay={200}>
                                <h4>{t('pillars-item1-title')}</h4>
                            </Fade>
                            <Fade triggerOnce duration={2500} delay={200}>
                                <p>{t('pillars-item1-text')}</p>
                            </Fade>
                        </div>
                    </Pillar>
                    <Pillar>
                        <Fade triggerOnce duration={2500}>
                            <h2 className="blue">02</h2>
                        </Fade>
                        <div>
                            <Fade triggerOnce duration={2500} delay={200}>
                                <h4>{t('pillars-item2-title')}</h4>
                            </Fade>
                            <Fade triggerOnce duration={2500} delay={200}>
                                <p>{t('pillars-item2-text')}</p>
                            </Fade>
                        </div>
                    </Pillar>
                    <Pillar>
                        <Fade triggerOnce duration={2500}>
                            <h2 className="blue">03</h2>
                        </Fade>
                        <div>
                            <Fade triggerOnce duration={2500} delay={200}>
                                <h4>{t('pillars-item3-title')}</h4>
                            </Fade>
                            <Fade triggerOnce duration={2500} delay={200}>
                                <p>{t('pillars-item3-text')}</p>
                            </Fade>
                        </div>
                    </Pillar>
                </Col>
            </Row>
            <ImgWrap id="pillars-img-wrapper">
                <img
                    width="760"
                    height="712"
                    loading="lazy"
                    src={LeftImg}
                    ref={leftImgRef}
                    alt={t('pillars-title')}
                />
                <img
                    width="580"
                    height="712"
                    loading="lazy"
                    src={RightImg}
                    ref={rightImgRef}
                    alt={t('pillars-title')}
                />
            </ImgWrap>
        </Container>
    )
}

export default Pillars
