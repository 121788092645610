import React from 'react'
import animejs from 'animejs'

import { Dot } from './styled'

const Line = () => {
    return (
        <svg
            width="5"
            height="55"
            fill="none"
            viewBox="0 0 5 55"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Dot delay={'0s'} cx="2.5" cy="2.5" r="2.5" fill="#000616" />
            <Dot delay={'0.15s'} cx="2.5" cy="12.5" r="2.5" fill="#000616" />
            <Dot delay={'0.3s'} cx="2.5" cy="22.5" r="2.5" fill="#000616" />
            <Dot delay={'0.45s'} cx="2.5" cy="32.5" r="2.5" fill="#000616" />
            <Dot delay={'0.6s'} cx="2.5" cy="42.5" r="2.5" fill="#000616" />
            <Dot delay={'0.75s'} cx="2.5" cy="52.5" r="2.5" fill="#000616" />
        </svg>
    )
}

export default Line
