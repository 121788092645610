import styled from 'styled-components'

import { media } from '@variables'

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Col = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    :not(:last-of-type) {
        padding-bottom: 40px;
    }

    ${media.tablet} {
        width: 46%;
    }

    img {
        width: 100%;
        height: auto;

        ${media.tablet} {
            max-width: 560px;
        }
    }

    h3 {
        padding: 26px 0 12px 0;

        ${media.tablet} {
            padding: 40px 0 14px 0;
        }
    }
`
