import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'

import { Routes } from '@variables'
import { Row, Col, Text } from './styled'
import Img from '@static/images/refocus.webp'
import Button from 'client/components/shared/Button'
import { Overflow } from 'client/components/shared/styled'
import Container from 'client/components/shared/Container'

const About = () => {
    const [t] = useTranslation()

    return (
        <Container maxWidth={970}>
            <Row>
                <Col>
                    <img
                        src={Img}
                        width="440"
                        height="351"
                        loading="lazy"
                        alt={t('refocus-title1')}
                    />
                </Col>
                <Col>
                    <Fade triggerOnce duration={2500}>
                        <h5>{t('refocus-title1')}</h5>
                    </Fade>
                    <Overflow>
                        <Fade triggerOnce duration={1500} direction="up">
                            <h3>
                                <span className="blue">
                                    {t('refocus-title2')}
                                </span>
                                <span>{t('refocus-title3')}</span>
                            </h3>
                        </Fade>
                    </Overflow>
                    <Fade triggerOnce duration={2500}>
                        <Text>{t('refocus-text')}</Text>
                    </Fade>
                    <Fade triggerOnce duration={2500}>
                        <div>
                            <Button href={Routes.REFOCUS}>
                                {t('learn-more')}
                            </Button>
                        </div>
                    </Fade>
                </Col>
            </Row>
        </Container>
    )
}

export default About
