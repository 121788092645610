import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="170"
            height="41"
            fill="none"
            viewBox="0 0 170 41"
            {...props}
        >
            <path
                fill="#000"
                d="M165.839 11.55c-1.807 0-3.203 1.397-3.203 3.223 0 1.827 1.396 3.223 3.203 3.223 1.808 0 3.203-1.396 3.203-3.223 0-1.826-1.395-3.223-3.203-3.223zm0 5.891c-1.476 0-2.585-1.19-2.585-2.668 0-1.477 1.109-2.668 2.585-2.668 1.486 0 2.586 1.19 2.586 2.668 0 1.477-1.1 2.668-2.586 2.668zm.466-2.489c.572-.107.805-.438.805-.904 0-.52-.313-1.066-1.306-1.066h-1.101v3.492h.653v-1.253c.501 0 .788-.045.958.492l.241.761h.725l-.242-.797c-.143-.456-.366-.644-.733-.725zm-.466-1.379c.412 0 .582.251.582.546 0 .27-.17.556-.582.556h-.483v-1.102h.483zM87.648 20.745h5.637c-.483-5.802-4.617-8.971-10.897-8.971-5.691 0-10.362 2.363-10.362 7.574 0 5.372 5.047 7.199 9.932 8.22 4.241.912 6.335 1.61 6.335 4.136 0 1.934-1.665 3.33-5.1 3.33-3.436 0-6.389-1.45-6.71-5.21h-5.53c.805 6.93 5.53 9.83 12.24 9.83s10.951-2.954 10.951-7.897c0-4.834-3.811-7.09-9.395-8.326-3.65-.806-6.871-1.236-6.871-4.137 0-2.202 1.664-3.115 4.187-3.115 3.383 0 5.261 1.611 5.584 4.566zM61.58 12.311v14.03c0 4.996-1.744 8.371-6.72 8.371s-5.79-3.429-5.79-8.371V12.31h-6.065v16.814c0 7.306 3.274 10.583 8.697 10.583 6.456 0 8.134-2.005 9.877-4.996v4.459h6.013v-26.86H61.58zM103.758 39.171v-26.86h-6.066v26.86h6.066zM120.62 39.708c8.052 0 13.26-5.426 13.26-13.913 0-8.542-5.208-13.968-13.26-13.968-7.999 0-13.206 5.426-13.206 13.968 0 8.487 5.207 13.913 13.206 13.913zm0-4.727c-4.671 0-7.194-3.868-7.194-9.186 0-5.372 2.523-9.24 7.194-9.24 4.724 0 7.193 3.868 7.193 9.24 0 5.318-2.469 9.186-7.193 9.186zM143.484 39.17V25.142c0-4.996 1.744-8.371 6.72-8.371s5.789 3.429 5.789 8.371v14.03h6.066V22.357c0-7.306-3.275-10.583-8.696-10.583-6.457 0-8.135 2.005-9.879 4.996v-4.46h-6.012v26.86h6.012z"
            ></path>
            <path
                fill="#5A5DEE"
                fillRule="evenodd"
                d="M19.608 24.622l14.54 14.549h8.046l-14.54-14.55h-8.046z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M23.403 33.02a13.41 13.41 0 01-3.83.555c-7.413 0-13.422-6.013-13.422-13.43s6.01-13.43 13.421-13.43c7.413 0 13.421 6.013 13.421 13.43 0 1.564-.267 3.065-.758 4.46l4.66 4.664a19.509 19.509 0 002.25-9.124C39.145 9.328 30.382.56 19.572.56S0 9.328 0 20.145 8.763 39.73 19.572 39.73c3.073 0 5.98-.708 8.567-1.97l-4.736-4.74zm3.179-1.42l4.433 4.436-4.433-4.436z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
