import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'

import { Row, Col } from './styled'
import PragueImg from '@static/images/prague.webp'
import Container from 'client/components/shared/Container'
import SanFranciscoImg from '@static/images/san-francisco.webp'
import { Overflow } from 'client/components/shared/styled'

const Offices = () => {
    const [t, i18n] = useTranslation()

    return (
        <Container maxWidth={1270}>
            <Row>
                <Col>
                    <img
                        src={PragueImg}
                        alt="Prague"
                        loading="lazy"
                        width="560"
                        height="560"
                    />
                    <Overflow>
                        <Fade triggerOnce direction="up" duration={1500}>
                            <h3>{t('prague')} (HQ)</h3>
                        </Fade>
                    </Overflow>
                    <Fade triggerOnce duration={2500}>
                        <p>Polská 26</p>
                    </Fade>
                    <Fade triggerOnce delay={150} duration={2500}>
                        <p>Prague 2</p>
                    </Fade>
                    <Fade triggerOnce delay={300} duration={2500}>
                        <p>Czechia</p>
                    </Fade>
                </Col>
                <Col>
                    <img
                        src={SanFranciscoImg}
                        alt="San Francisco"
                        loading="lazy"
                        width="560"
                        height="560"
                    />
                    <Overflow>
                        <Fade triggerOnce direction="up" duration={1500}>
                            <h3>{t('san-francisco')}</h3>
                        </Fade>
                    </Overflow>
                    <Fade triggerOnce duration={2500}>
                        <p>Tehama st.44</p>
                    </Fade>
                    <Fade triggerOnce delay={150} duration={2500}>
                        <p>San Francisco</p>
                    </Fade>
                    <Fade triggerOnce delay={300} duration={2500}>
                        <p>United States</p>
                    </Fade>
                </Col>
            </Row>
        </Container>
    )
}

export default Offices
