import React from 'react'
import { useTranslation } from 'react-i18next'

import { Row, Col, ImgWrap } from './styled'
import CopyRightEuImg from '@static/images/copyright-eu.webp'
import Container from 'client/components/shared/Container'
import CopyRightPragueImg from '@static/images/copyright-prague.webp'

const Copyright = () => {
    const [t, i18n] = useTranslation()

    return (
        <Container maxWidth={1130}>
            <Row>
                <Col>
                    <ImgWrap>
                        <img
                            alt="eu"
                            width="310"
                            height="61"
                            loading="lazy"
                            src={CopyRightEuImg}
                            style={{ maxWidth: 300 }}
                        />
                        <img
                            alt="prague"
                            width="63"
                            height="61"
                            loading="lazy"
                            src={CopyRightPragueImg}
                        />
                    </ImgWrap>
                </Col>
                <Col>
                    <p className="gray1">{t('copyright')}</p>
                </Col>
            </Row>
        </Container>
    )
}

export default Copyright
