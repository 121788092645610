import { media } from '@variables'
import styled from 'styled-components'

export const Row = styled.div`
    width: 100%;
    position: relative;

    display: flex;
    flex-direction: column;

    padding: 80px 0;
    margin: 100px 0 0 0;

    ${media.tablet} {
        padding: 110px 0;
    }
    ${media.desktop} {
        margin: 200px 0 100px 0;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }

    :before {
        top: 0;
        left: 0;
        content: ' ';
        width: 100%;
        height: 1px;
        opacity: 0.2;
        display: block;
        position: absolute;
        background-color: #979797;
    }
`

export const Col = styled.div`
    width: 100%;
    display: flex;
    max-width: 540px;
    font-size: 13px;
    flex-direction: column;

    @media (max-width: 959px) {
        :not(:last-of-type) {
            margin-bottom: 40px;
        }
    }

    ${media.desktop} {
        :first-of-type {
            width: 42%;
        }
        :last-of-type {
            width: 50%;
        }
    }
`

export const ImgWrap = styled.div`
    width: 100%;
    display: flex;
    max-width: 420px;
    flex-direction: column;

    @media (max-width: 479px) {
        img {
            margin-bottom: 24px;
        }
    }

    ${media.phone} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`
