import { useSetState } from 'react-use'
import { Link } from 'react-router-dom'
import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

import { Routes } from '@variables'
import Button from '../shared/Button'
import MobileMenu from './MobileMenu'
import Container from '../shared/Container'
import LogoImg from '@static/images/logo.webp'
import LanguageSwitch from '../shared/LanguageSwitch'
import { Nav, Logo, Menu, Burger, NavContent, Right } from './styled'
import useDocumentScrollThrottled from './useDocumentScrollThrottled'

const NavBar = () => {
    const [t] = useTranslation()

    const [{ visible, isBkgFilled, isMobileMenuOpen }, setState] = useSetState({
        visible: true,
        prevScrollpos: 0,
        isBkgFilled: false,
        isMobileMenuOpen: false
    })

    const toggleOpen = () => {
        setState({ isMobileMenuOpen: !isMobileMenuOpen })
        if (isMobileMenuOpen) document.body.classList.remove('block-scroll')
        else document.body.classList.add('block-scroll')
    }

    const closeMobileMenu = () => {
        setState({ isMobileMenuOpen: false })
        document.body.classList.remove('block-scroll')
    }

    useDocumentScrollThrottled(
        ({
            previousScrollTop,
            currentScrollTop
        }: {
            previousScrollTop: number
            currentScrollTop: number
        }) => {
            const isScrolledUp = previousScrollTop > currentScrollTop

            setTimeout(() => {
                setState({
                    visible: currentScrollTop < 50 ? true : isScrolledUp,
                    isBkgFilled: currentScrollTop > 50 ? true : false
                })
            }, 0)
        }
    )

    return (
        <>
            <Nav
                isVisible={visible}
                isBkgFilled={isBkgFilled}
                isMobileMenuOpen={isMobileMenuOpen}
            >
                <Container style={containerStyle} maxWidth={1310}>
                    <NavContent>
                        <Menu>
                            <li>
                                <Link to={Routes.BLOG}>{t('nav-blog')}</Link>
                            </li>
                        </Menu>

                        <Logo onClick={closeMobileMenu}>
                            <Link to={Routes.HOME}>
                                <img
                                    alt="VOS - Logo"
                                    loading="lazy"
                                    src={LogoImg}
                                    width="144"
                                    height="31"
                                />
                            </Link>
                        </Logo>

                        <Right>
                            {/* <LanguageSwitch /> */}
                            <Button to={Routes.CONTACTS}>
                                {t('nav-contact')}
                            </Button>
                        </Right>

                        <Burger
                            role="button"
                            onClick={toggleOpen}
                            isOpen={isMobileMenuOpen}
                        >
                            <span />
                            <span />
                            <span />
                        </Burger>
                    </NavContent>
                </Container>
            </Nav>

            <MobileMenu
                isOpen={isMobileMenuOpen}
                closeMobileMenu={closeMobileMenu}
            />
        </>
    )
}

const containerStyle = { zIndex: 4, position: 'relative' } as CSSProperties

export default NavBar
