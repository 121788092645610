import React, { useRef, useState } from 'react'

import { Wrap, Video, Button } from './styled'
import MiniVideo from '@static/video/mini-video.mp4'
import MobileVideo from '@static/video/qusion-mobile.mp4'
import VideoPlayButton from 'client/components/SVG/VideoPlayButton'

const VideoMobile = () => {
    const [display, setDisplay] = useState(false)
    const videoFullscreenMobile = useRef<TODO_ANY>(null)

    const playVideo = () => {
        const myVideo = videoFullscreenMobile.current

        if (!myVideo) return

        if (myVideo.paused) {
            if (myVideo.requestFullscreen) {
                myVideo.requestFullscreen()
            } else if (myVideo.msRequestFullscreen) {
                myVideo.msRequestFullscreen()
            } else if (myVideo.mozRequestFullScreen) {
                myVideo.mozRequestFullScreen()
            } else if (myVideo.webkitRequestFullScreen) {
                // TODO: fix (not working in safari)
                // myVideo.webkitRequestFullScreen()
            }
            myVideo.play()
        } else {
            myVideo.pause()
        }
    }

    return (
        <Wrap>
            <Video
                loop
                muted
                autoPlay
                id="video-mobile"
                style={{ display: display ? 'none' : 'block' }}
            >
                <track kind="captions" />
                <source src={MiniVideo} type="video/mp4" />
                Your browser does not support HTML5 video.
            </Video>
            <Video
                controls
                id="video-fullscreen-mobile"
                ref={videoFullscreenMobile}
                style={{ display: display ? 'block' : 'none' }}
            >
                <track kind="captions" />
                <source src={MobileVideo} type="video/mp4" />
                Your browser does not support HTML5 video.
            </Video>
            <Button
                onClick={() => {
                    setDisplay(true)
                    playVideo()
                }}
                style={{ display: display ? 'none' : 'block' }}
            >
                <VideoPlayButton size={70} />
            </Button>
        </Wrap>
    )
}

export default VideoMobile
