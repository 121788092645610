import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'

import { Wrap, Row } from './styled'
import Container from 'client/components/shared/Container'
import { Overflow } from 'client/components/shared/styled'

const About = () => {
    const [t] = useTranslation()

    return (
        <Container maxWidth={870}>
            <Wrap>
                <Overflow>
                    <Fade triggerOnce duration={1500} direction="up">
                        <h3>{t('about-title')}</h3>
                    </Fade>
                </Overflow>
                <Row>
                    <Fade triggerOnce duration={2500} delay={200}>
                        <p>{t('about-text1')}</p>
                    </Fade>
                    <Fade triggerOnce duration={2500} delay={400}>
                        <p>{t('about-text2')}</p>
                    </Fade>
                </Row>
            </Wrap>
        </Container>
    )
}

export default About
