import styled from 'styled-components'

import { colors, media } from '@variables'

export const Ul = styled.ul`
    padding: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    margin: 20px 0 42px 0;

    ${media.tablet} {
        margin: 40px 0 64px 0;
    }
`

export const Li = styled.li<{ active?: boolean }>`
    margin: 6px 18px;
    display: inline;
    cursor: pointer;
    list-style: none;
    padding-left: 12px;
    color: ${({ active }) => (active ? colors.secondary.blue : 'inherit')};

    span {
        opacity: 0.8;
        display: block;
        position: relative;

        :before {
            top: 5px;
            left: -12px;
            opacity: 0.8;
            width: 5px;
            height: 5px;
            content: ' ';
            position: absolute;
            border-radius: 5px;
            background-color: ${({ active }) =>
                active ? colors.secondary.blue : colors.primary.black};
        }
    }
`
