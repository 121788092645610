import React from 'react'
import { useTranslation } from 'react-i18next'

import { Row, Col, Link } from './styled'
import ForbesImg from '@static/images/forbes.webp'
import CzechcrunchImg from '@static/images/czechcrunch.webp'
import AlfaImg from '@static/images/alfa.webp'
import E15Img from '@static/images/e15.webp'
import CnnImg from '@static/images/cnn.webp'
import DailyImg from '@static/images/daily.webp'
import Container from 'client/components/shared/Container'

const InMedia = () => {
    const [t] = useTranslation()

    return (
        <Container maxWidth={1230}>
            <Row>
                <Col>
                    <h2>Qusion</h2>
                    <h2>
                        <span className="blue-and-underlined">
                            {t('in-media-title')}
                        </span>
                    </h2>
                </Col>
                <Col>
                    {data.map((i) => (
                        <Link
                            href={i.url}
                            key={i.name}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img
                                src={i.img}
                                alt={i.name}
                                width="146"
                                height="80"
                                loading="lazy"
                            />
                        </Link>
                    ))}
                </Col>
            </Row>
        </Container>
    )
}

const data = [
    {
        name: 'forbes',
        img: ForbesImg,
        url:
            'https://forbes.cz/dalsich-dvacet-milionu-do-dusevniho-zdravi-ceskou-aplikaci-vos-health-podporil-reflex-capital-miliardare-fryce/'
    },
    {
        name: 'czechcrunch',
        img: CzechcrunchImg,
        url:
            'https://cc.cz/zacali-jsme-brzo-a-bylo-to-to-nejlepsi-co-jsme-mohli-udelat-rikaji-extremne-mladi-podnikatele-jiri-diblik-a-ondrej-kopecky/'
    },
    {
        name: 'alfa',
        img: AlfaImg,
        url:
            'https://zpravy.aktualne.cz/finance/podnikatele-kteri-rozjeli-byznys-jako-deti/r~710f0336fc2111ea9c800cc47ab5f122/'
    },
    {
        name: 'e15',
        img: E15Img,
        url:
            'https://www.e15.cz/byznys/technologie-a-media/apple-a-google-si-berou-30-procent-z-ceny-aplikace-vyvojari-jsou-nastvani-rika-sef-firmy-qusion-1373378'
    },
    {
        name: 'cnn',
        img: CnnImg,
        url: '#'
    },
    {
        name: 'daily',
        img: DailyImg,
        url:
            'https://dailyplanetdc.com/2021/06/30/vos-health-a-mental-health-care-platform-has-received-another-investment/'
    }
]

export default InMedia
