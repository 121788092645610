import React from 'react'

import { Ul, Li } from './styled'

type Props = {
    categories: string[]
    activeCategory: string
    setActiveCategory: (c: string) => void
}

const Filter = ({ categories, activeCategory, setActiveCategory }: Props) => {
    return (
        <Ul>
            {categories.map((item) => (
                <Li
                    key={item}
                    active={item === activeCategory}
                    onClick={() => setActiveCategory(item)}
                >
                    <h6>
                        <span>{item}</span>
                    </h6>
                </Li>
            ))}
        </Ul>
    )
}

export default Filter
