import { Button } from './../../components/shared/LanguageSwitch/styled'
import { media } from '@variables'
import styled from 'styled-components'

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.desktop} {
        flex-direction: row;
        justify-content: space-between;
    }

    padding: 80px 0;

    ${media.tablet} {
        padding: 100px 0 120px 0;
    }
    ${media.desktop} {
        padding: 120px 0 160px 0;
    }
    ${media.xl} {
        padding: 220px 0 240px 0;
    }
`

export const Col = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${media.desktop} {
        flex: 1 1 auto;
    }

    img {
        width: 100%;
        height: auto;

        ${media.desktop} {
            max-width: 440px;
        }
    }

    :first-of-type {
        order: 2;
        margin-top: 70px;

        ${media.desktop} {
            order: 1;
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
    :last-of-type {
        order: 1;

        ${media.desktop} {
            max-width: 385px;
            order: 2;
        }
    }
`

export const Text = styled.p`
    padding: 10px 0 30px 0;
`
