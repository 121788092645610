import styled from 'styled-components'

import { media } from '@variables'

export const Row = styled.div`
    flex-wrap: wrap;
    display: flex;
    width: 100%;
`

export const Col = styled.div<{ special?: boolean }>`
    width: 100%;
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: center;

    :not(:last-child) {
        padding-bottom: 70px;
    }

    :nth-last-child(2) {
        ${media.desktop} {
            padding-bottom: ${({ special }) => (special ? '70px' : '0')};
        }
    }

    ${media.desktop} {
        display: block;
        max-width: 50%;
        flex: 0 0 50%;
        padding-bottom: 0;
        justify-content: flex-start;
    }

    &:nth-child(even) {
        ${media.desktop} {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-top: ${({ special }) => (special ? '80px' : '0px')};
        }
    }
`

export const ButtonWrapper = styled.div<{ expanded?: boolean }>`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: ${({ expanded }) => (expanded ? '0px' : '50px')};

    ${media.desktop} {
        padding-top: ${({ expanded }) => (expanded ? '0px' : '100px')};
    }
`
