import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Filter from './Filter'
import { Wrap } from './styled'
import { categories } from '@variables'
import Posts, { PostType } from 'client/components/Posts'
import Container from 'client/components/shared/Container'

type Props = {
    data: Post[]
}

const BlogList = ({ data }: Props) => {
    const [t] = useTranslation()
    const [category, setCategory] = useState('all')

    return (
        <Container maxWidth={1090}>
            <Wrap>
                <h2 className="blue">{t('blog')}</h2>
                <Filter
                    categories={categories}
                    activeCategory={category}
                    setActiveCategory={setCategory}
                />
                <Posts
                    limit={6}
                    posts={data}
                    category={category}
                    type={PostType.NONE}
                    setCategory={setCategory}
                />
            </Wrap>
        </Container>
    )
}

export default BlogList
