import styled from 'styled-components'

import { media } from '@variables'

export const LogoImage = styled.div<{ clientLogo: string }>`
    opacity: 1;
    width: 90px;
    height: 40px;
    background-size: 100%;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    transform: scale(1);
    background-image: url(${props => props.clientLogo});

    ${media.tablet} {
        margin: 55px 0;
    }
`
