import styled from 'styled-components'

import { media, transition } from '@variables'

export const Wrap = styled.section`
    width: 100%;

    padding: 80px 0;

    ${media.tablet} {
        padding: 280px 0;
    }

    .swiper-wrapper {
        width: 100%;
        margin: 0 auto;
    }

    .swiper-slide {
        width: 100%;
        max-width: 312px;
        white-space: pre-wrap;
        box-sizing: border-box;

        ${media.tablet} {
            max-width: 624px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
`

export const Slide = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ${transition.main};

    width: 448px;
    height: 448px;

    ${media.desktop} {
        width: 624px;
        height: 624px;
    }
`
