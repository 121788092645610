import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'

import { Routes } from '@variables'
import VosImg from '@static/images/vos.webp'
import TeamImg from '@static/images/team.webp'
import AppImg from '@static/images/vos-app.webp'
import Button from 'client/components/shared/Button'
import { Overflow } from 'client/components/shared/styled'
import Container from 'client/components/shared/Container'
import {
    Head,
    HeadCol,
    Bottom,
    BottomCol,
    Img,
    Title,
    BottomImg
} from './styled'

const Focus = () => {
    const [t] = useTranslation()

    return (
        <Container maxWidth={1090}>
            <Head>
                <HeadCol>
                    <Fade triggerOnce duration={2500}>
                        <h4 className="blue">{t('focus-title1')}</h4>
                    </Fade>
                    <Overflow>
                        <Fade triggerOnce duration={1500} direction="up">
                            <Title>{t('focus-title2')}</Title>
                        </Fade>
                    </Overflow>
                </HeadCol>
                <HeadCol>
                    <img
                        src={VosImg}
                        loading="lazy"
                        width="188"
                        height="90"
                        alt="vos"
                    />
                    <Fade triggerOnce duration={2500}>
                        <p>{t('focus-text1')}</p>
                    </Fade>
                </HeadCol>
            </Head>
            <Img
                src={TeamImg}
                alt="Vos team"
                loading="lazy"
                width="1060"
                height="589"
            />
            <Bottom>
                <BottomCol>
                    <BottomImg
                        src={AppImg}
                        alt="vos app"
                        width="466"
                        height="715"
                        loading="lazy"
                    />
                </BottomCol>
                <BottomCol>
                    <Overflow>
                        <Fade triggerOnce duration={1500} direction="up">
                            <h4>{t('focus-title3')}</h4>
                        </Fade>
                    </Overflow>
                    <Fade triggerOnce duration={2500}>
                        <p>{t('focus-text2')}</p>
                    </Fade>
                    <Fade triggerOnce duration={2500}>
                        <div>
                            <Button href={Routes.VOS}>{t('learn-more')}</Button>
                        </div>
                    </Fade>
                </BottomCol>
            </Bottom>
        </Container>
    )
}

export default Focus
