import { media } from '@variables'
import styled from 'styled-components'

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.desktop} {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Col = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        padding-bottom: 40px;

        ${media.desktop} {
            max-width: 335px;
            padding-bottom: 0px;
        }
    }

    :first-of-type {
        ${media.desktop} {
            width: 360px;

            height: auto;
            position: relative;

            .pillars-title {
                top: 50%;
                position: sticky;
            }
        }
    }
    :last-of-type {
        ${media.desktop} {
            width: calc(100% - 360px);
        }
    }
`

export const Pillar = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.phone} {
        flex-direction: row;
    }

    ${media.desktop} {
        max-width: 515px;
    }

    div {
        ${media.phone} {
            padding: 0 0 0 20px;
        }
        ${media.desktop} {
            padding: 35px 0 0 40px;
        }
    }
    h4 {
        padding: 10px 0;

        ${media.phone} {
            padding: 0 0 10px 0;
        }
    }
    p {
        width: 100%;

        ${media.phone} {
            min-width: 320px;
        }
        ${media.desktop} {
            max-width: 370px;
        }
    }

    :not(:last-of-type) {
        padding-bottom: 40px;

        ${media.desktop} {
            padding-bottom: 100px;
        }
    }

    :nth-child(2) {
        ${media.xl} {
            margin-left: 17%;
        }
    }

    :last-of-type {
        ${media.xl} {
            margin-left: auto;
        }
    }
`

export const ImgWrap = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-end;

    height: 360px;

    ${media.custom(420)} {
        height: 420px;
    }
    ${media.phone} {
        height: 540px;
    }
    ${media.custom(600)} {
        height: 640px;
    }
    ${media.desktop} {
        height: 800px;
    }
    ${media.xl} {
        height: 1000px;
    }

    img {
        width: 100%;
        height: auto;
        position: absolute;

        // LEFT
        :first-of-type {
            left: 0;
            z-index: 0;
        }
        // RIGHT
        :last-of-type {
            right: 0;
            z-index: 1;
        }

        :first-of-type {
            max-width: 60%;
        }
        :last-of-type {
            max-width: 50%;
        }

        ${media.xl} {
            :first-of-type {
                max-width: 760px;
            }
            :last-of-type {
                max-width: 580px;
            }
        }
    }
`
