import React from 'react'
import { useTranslation } from 'react-i18next'

import { Wrapper } from './styled'
import Button from 'client/components/shared/Button'

type Props = {
    show: boolean
    count: number
    setCategory?: (c: string) => void
}

const NoArticles = ({ show, setCategory, count }: Props) => {
    const [t, i18n] = useTranslation()

    const onClick = () => {
        if (count === 0) {
            i18n.changeLanguage(i18n.language === 'cs' ? 'en' : 'cs')
        } else {
            if (setCategory) setCategory('all')
        }
    }

    if (!show) {
        return null
    }

    return (
        <Wrapper>
            <h1>{t('no-blog-posts')}</h1>
            <Button onClick={onClick}>{t('show-all')}</Button>
        </Wrapper>
    )
}

export default NoArticles
