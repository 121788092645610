import React, { useEffect } from 'react'
import get from 'lodash/get'
import { useQuery } from 'react-apollo'
import { useLocation } from 'react-router-dom'

import { cmsQueries, Routes } from '@variables'
import Head from 'client/components/shared/Head'
import BlogPost from 'client/sections/BlogPost'
import { unblockScroll } from '@utils'

const Post = () => {
    const location = useLocation()
    const path = location.pathname.split('/')
    const slug = get(path, path.length - 1)

    const { data } = useQuery(cmsQueries.POSTS, {
        onError: (err) => console.error(err.message)
    })

    const posts: Post[] = get(data, 'posts', [])
    const post: Post | undefined = posts.find(
        (post: Post) => post.slug === slug
    )

    const ogImg = post?.mainImg || undefined
    const description = post?.ogDescription || undefined
    const title = post?.ogTitle || post?.title || undefined
    const ogUrl = `${process.env.RAZZLE_HOST}${Routes.BLOG}/${post?.slug}`

    useEffect(() => {
        unblockScroll()
    }, [])

    return (
        <>
            <Head
                ogUrl={ogUrl}
                title={title}
                ogImgUrl={ogImg}
                description={description}
            />
            <BlogPost post={post} posts={posts} />
        </>
    )
}

export default Post
